import classNames from "classnames";
import React from "react";
import style from "./responsive-template-card-new.module.css";
import { TemplateCardStyle } from "./template-card";
import { AllTemplatesIcon } from "frontend/icons/all-templates";
import tracking from "frontend/tracking";
import consts from "shared/consts";

export function ShowAllTemplatesCardNew({
  cardStyle = TemplateCardStyle.regular,
  setShowTemplatesModal,
}: {
  cardStyle?: TemplateCardStyle;
  setShowTemplatesModal: (show: boolean) => void;
}) {
  const isSmall = cardStyle === TemplateCardStyle.small;

  return (
    <>
      <div
        className={classNames(style.gridContainer)}
        onClick={() => {
          tracking.trackAnalyticsEvent("templates-library-clicked", {
            category: consts.TRACKING_CATEGORY.TEMPLATES,
            source: "template-section",
            isNewHomePage: true,
          });
          setShowTemplatesModal(true);
        }}
      >
        <div className={classNames(style.templateImage, style.menuIcon, { [style.small]: isSmall })}>
          <div className={style.allTemplatesIcon}>
            <AllTemplatesIcon />
          </div>
        </div>
        <div className={classNames(style.templateTitle, { [style.small]: isSmall })}>All templates</div>
      </div>
    </>
  );
}
