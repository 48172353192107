import { useWindowSize } from "react-use";
import { BlankCanvasTemplate, CanvasTemplateMinimal } from "shared/datamodel/schemas";
import { ResponsiveTemplateCardNew } from "./responsive-template-card-new";
import { ShowAllTemplatesCardNew } from "./show-all-templates-new";
import Skeleton from "react-loading-skeleton";
import style from "./explore-templates-list-new.module.css";

//the width of the home page elements
const SIDE_MENU = 226;
const TEMPLATE_SECTION_SIDE_MARGIN = 37 * 2;
const TEMPLATE_SECTION_SIDE_PADDING = 22 * 2;
const SINGLE_TEMPLATE = 190;

const SIDE_MENU_FOR_SKELETON = 224;
const SKELETON_ELEMENT_SIZE = 190;
const SKELETON_COUNT_BOUND = 1;

export default function ExploreTemplatesListNew({
  templates,
  onCreateBoard,
  onPreviewClicked,
  setShowTemplatesModal,
}: {
  templates: CanvasTemplateMinimal[] | null;
  onCreateBoard: (template: CanvasTemplateMinimal) => void;
  onPreviewClicked: (template: CanvasTemplateMinimal) => void;
  setShowTemplatesModal: (show: boolean) => void;
}) {
  const windowSize = useWindowSize();
  const { width } = windowSize;

  const spaceForTemplates =
    width -
    SIDE_MENU -
    TEMPLATE_SECTION_SIDE_MARGIN -
    TEMPLATE_SECTION_SIDE_PADDING -
    SINGLE_TEMPLATE -
    SINGLE_TEMPLATE; // 'new canvas' and 'all templates'

  const lastIndex =
    Math.floor(spaceForTemplates / SINGLE_TEMPLATE) > 0 ? Math.floor(spaceForTemplates / SINGLE_TEMPLATE) : 0;

  const skeletonCount =
    Math.floor((window.innerWidth - SIDE_MENU_FOR_SKELETON) / SKELETON_ELEMENT_SIZE) - SKELETON_COUNT_BOUND;

  if (!templates) {
    return <Skeleton count={skeletonCount} className={style.skeleton} containerClassName={style.skeletonContainer} />;
  }

  return (
    <>
      <ResponsiveTemplateCardNew
        template={BlankCanvasTemplate}
        previewAvailable={false}
        onClick={onCreateBoard}
        onPreview={() => onPreviewClicked(BlankCanvasTemplate)}
      />
      {templates.slice(0, lastIndex).map((template) => (
        <ResponsiveTemplateCardNew
          key={template.id}
          template={template}
          previewAvailable={template.alias !== "blank"}
          onClick={onCreateBoard}
          onPreview={() => onPreviewClicked(template)}
        />
      ))}
      <ShowAllTemplatesCardNew setShowTemplatesModal={setShowTemplatesModal} />
    </>
  );
}
