import React, { useEffect, useState, useRef, useMemo } from "react";
import style from "./boards-grid-new.module.css";
import { Board, BoardState } from "shared/datamodel/schemas/board";
import { useRouter } from "next/router";
import useStateValue from "frontend/state/value";
import AppHeader from "frontend/app-header/app-header";
import { createBoardNew } from "frontend/services/boardsService";
import useAuthentication from "frontend/hooks/use-authentication";
import Modal from "frontend/modal/modal";
import MaxUsersPerBoardError from "frontend/error/max-users-per-board-error";
import StyledButton from "frontend/ui-components/styled-button";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import EditSeatsModal from "frontend/modals/edit-seats-modal";
import { useAtom, useAtomValue } from "jotai";
import { isBannerNotificationOnAtom, isBusyAtom } from "state-atoms/general-atoms";
import { BlankCanvasId, CanvasTemplate } from "shared/datamodel/schemas/canvas-template";
import TemplatesSectionNew from "./templates-section-new";
import Checkout from "frontend/billing/checkout";
import classNames from "classnames";
import { InviteMode, userTipsAtom } from "state-atoms";
import { Team } from "shared/datamodel/schemas/team";
import CollaborationHeader from "frontend/modals/collaboration/collaboration-header";
import { PortalID } from "frontend/modal/portal-types";
import { Account, TipName } from "shared/datamodel/schemas";
import MoveCanvasModalNew from "frontend/modals/collaboration/move-canvas-modal-new";
import MoveCanvasToast from "frontend/ui-components/move-canvas-toast";
import UpgradeModalAfterDowngrade from "frontend/modals/upgrade/upgrade-after-downgrade";
import useFeatureValue from "frontend/hooks/use-features";
import useModalDeepLink from "frontend/hooks/use-modal-deep-link";
import ModalManager, { ModalAction, ModalType } from "frontend/modals/modal-manager";
import { RESET } from "jotai/utils";
import { InstanceType } from "shared/datamodel/schemas/ability";
import useAbility from "frontend/hooks/use-ability";
import RestrictedUserModal from "frontend/modals/restricted-user-modal";
import InviteModalNew from "frontend/modals/invite-modal-new";
import RestrictedUserNoticeModal from "frontend/modals/restricted-user-notice-modal";
import { markTipSeen } from "frontend/api";
import { getPathPrefix } from "../utils/getPathPrefix";
import { SearchSection } from "frontend/boards-grid/search-section/search-section";
import { useRecentSelected } from "frontend/boards-grid/search-section/hooks/use-recent-selected";
import SideMenuNew from "./side-menu/side-menu-new";
import BoardsBySection from "./boards-by-section";
import useBoards from "frontend/hooks/use-boards";
import AccountMembers from "frontend/modals/collaboration/account-members";
import { AccountSwitcher } from "./side-menu/account-switcher";
import ConfirmCreateCanvasModal from "./confirm-create-canvas-modal";
import ModalContainer from "frontend/modals/modal-container";
import { useTeamsWithProjects } from "frontend/hooks/caching/use-teams-with-projects";
import { BoardSectionType, BoardSection } from "shared/datamodel/schemas/board-section";
import WalkthroughTipPresenter from "frontend/walkthrough-tips/walkthrough-tip-presenter";
import HomePageRefactorAnnouncement from "./home-page-refactor-announcement";
import { WalkthroughTipType } from "frontend/walkthrough-tips/walkthrough-tip-model";
import {
  getLocalStorageItem,
  isLocalStorageAvailable,
  removeItemFromLocalStorage,
  setLocalStorageItem,
} from "utils/storage-utils";
import { useInstanceMembers } from "frontend/hooks/use-instance-members";

export type GridError = {
  type: "limitReached";
  data: string | number;
};

export function BoardsGridNew({ showGridError }: { showGridError?: GridError | null }) {
  enum AdminModalType {
    inviteModal = 0,
    EditSeatsModal = 1,
  }

  const [{ user }, dispatch] = useStateValue();
  const [error, setError] = useState<JSX.Element | null>(null);
  const { isReady } = useAuthentication(true);
  const [adminModalTypeToShow, setAdminModalTypeToShow] = useState<AdminModalType | null>(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showDowngradeNoticeModal, setShowDowngradeNoticeModal] = useState<boolean>(false);
  const [showSubscriptionCanceledNotice, setShowSubscriptionCanceledNotice] = useState<boolean>(false);
  const [showRestrictedNoticedModal, setShowRestrictedNoticeModal] = useState<boolean>(false);
  const [showAccountMembers, setShowAccountMembers] = useState(false);
  const [showHomePageRefactorTip, setShowHomePageRefactorTip] = useState(false);
  const [canvasToMove, setCanvasToMove] = useState<Board | null>(null);
  const [isBusy, setIsBusy] = useAtom(isBusyAtom);
  const userTips = useAtomValue(userTipsAtom);
  const isBannerNotificationOn = useAtomValue(isBannerNotificationOnAtom);
  const [canvasMovingMessage, setCanvasMovingMessage] = useState<JSX.Element | null>(null);
  const { onSelected } = useRecentSelected();

  const [inviteSource, setInviteSource] = useState<string>("board-grid-app-header");
  const [selectedSection, setSelectedSection] = useState<BoardSection>({
    id: 0,
    type: BoardSectionType.RECENT,
  });
  const [approveModalType, setApproveModalType] = useState<InstanceType | null>(null);
  const [createCanvasProps, setCreateCanvasProps] = useState<{
    template: Omit<CanvasTemplate, "data"> & { data?: Record<string, unknown> };
    inputProjectId?: number | null;
    inputTeamId?: number | null;
  } | null>();
  const inviteToAccountRef = useRef<HTMLDivElement>(null);
  const { canCreateInstance, canPerformAnyAction } = useAbility();
  const { fetchTeamsWithProjects, teamsWithProjects } = useTeamsWithProjects();

  const selectedTeamId = useMemo(
    () => (selectedSection.type === BoardSectionType.TEAM ? selectedSection.id : null),
    [selectedSection]
  );
  const selectedProjectId = useMemo(
    () => (selectedSection.type === BoardSectionType.PROJECT ? selectedSection.id : null),
    [selectedSection]
  );
  const instance = useMemo(() => {
    if (teamsWithProjects.length === 0) {
      return null;
    }
    if (selectedProjectId) {
      const team = teamsWithProjects.find((team) => team.projects?.find((project) => project.id === selectedProjectId));
      return team?.projects?.find((project) => project.id === selectedProjectId);
    }
    return teamsWithProjects.find((team) => team.id === selectedTeamId);
  }, [selectedProjectId, selectedTeamId, teamsWithProjects]);

  const instanceType = selectedProjectId ? InstanceType.Project : InstanceType.Team;
  const { members } = useInstanceMembers(selectedSection.id.toString(), instanceType);

  const shouldShoWRestrictedModal = user && instance && !canPerformAnyAction(user, instance as Team, instanceType);

  const maxAllowedCanvasesString = useFeatureValue(consts.FEATURE_NAMES.MAX_ALLOWED_CANVASES);
  const applyRestrictedLimitation = useFeatureValue(consts.FEATURE_NAMES.APPLY_VIEWER_RESTRICTED) === "true";

  const { setModalDeepLink } = useModalDeepLink();
  const isMondayUser = user?.account?.id === "5";

  const router = useRouter();
  const { boards } = useBoards();

  // Helper function to convert a section type and optional ID to a URL path
  const sectionToPath = (section: BoardSectionType, id?: number): string => {
    switch (section) {
      case BoardSectionType.PROJECT: {
        return id ? `/project/${id}` : "/";
      }
      case BoardSectionType.TEAM: {
        return id ? `/team/${id}` : "/";
      }
      case BoardSectionType.MY_BOARDS: {
        return "/my-boards";
      }
      case BoardSectionType.SHARED_WITH_ME: {
        return "/shared-with-me";
      }
      case BoardSectionType.RECENT: {
        return "/";
      }
      default: {
        return "/";
      }
    }
  };

  const pathToSection = (pathParams: string[]): BoardSection => {
    const id = pathParams.length > 1 ? Number(pathParams[1]) : null;
    switch (pathParams[0]) {
      case "project": {
        return id ? { id: id, type: BoardSectionType.PROJECT } : pathToSection(["/"]);
      }
      case "team": {
        return id ? { id: id, type: BoardSectionType.TEAM } : pathToSection(["/"]);
      }
      case "my-boards": {
        return { id: 0, type: BoardSectionType.MY_BOARDS };
      }
      case "shared-with-me": {
        return { id: 0, type: BoardSectionType.SHARED_WITH_ME };
      }
      default: {
        return { id: 0, type: BoardSectionType.RECENT };
      }
    }
  };

  // Helper function to navigate to a path with shallow routing
  const navigateToSection = (section: BoardSectionType, id?: number) => {
    if (section === BoardSectionType.RECENT) {
      // if we are navigating to the recent section, we need to remove the selectedSection from local storage
      removeItemFromLocalStorage("selectedSection");
    }
    const path = sectionToPath(section, id);
    router.push(path, undefined, { shallow: true });
  };

  useEffect(() => {
    fetchTeamsWithProjects();
  }, []);

  useEffect(() => {
    // Parse the params array when it's available
    if (!router.isReady) {
      return;
    }

    const params = router.query.params ?? [];
    const paramsArray = Array.isArray(params) ? params : params.split("/");

    if (isLocalStorageAvailable()) {
      const selectedSection = getLocalStorageItem<BoardSection>("selectedSection");
      const urlSection = pathToSection(paramsArray);
      if (urlSection.type !== BoardSectionType.RECENT) {
        // section from url always wins
        setSelectedSection(urlSection);
      } else if (selectedSection) {
        // no section from url, use the section from local storage
        navigateToSection(selectedSection.type, selectedSection.id);
      } else {
        // no section from url or local storage, set the recent section
        setSelectedSection({ id: 0, type: BoardSectionType.RECENT });
      }
    }
  }, [router.isReady, router.query.params]);

  useEffect(() => {
    // handle the case the selected team or project does not exist anymore
    if ((selectedTeamId || selectedProjectId) && !instance && teamsWithProjects.length > 0) {
      navigateToSection(BoardSectionType.RECENT);
    }
  }, [selectedTeamId, selectedProjectId, instance, teamsWithProjects]);

  useEffect(() => {
    if (router && router.query.board_users_limit && router.query.maxAllowedCollaborators) {
      const boardName = decodeURIComponent(router.query.board_users_limit.toString());
      const maxAllowedCollaborators = decodeURIComponent(router.query.maxAllowedCollaborators.toString());
      router.query = {};
      setError(
        <MaxUsersPerBoardError
          boardName={boardName}
          onDismiss={() => {
            setError(null);
            navigateToSection(BoardSectionType.RECENT);
          }}
          maxAllowedCollaborators={Number.parseInt(maxAllowedCollaborators)}
        />
      );
    }
  }, [router]);

  function showUpgradeRequiredModal(numberOfCanvases: number) {
    if (numberOfCanvases === 0) {
      return null;
    }
    tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.CANVAS_LIMIT_REACHED);
    return (
      <ModalContainer onDismiss={() => setError(null)}>
        <div className={style.maxBoardsReachedModal}>
          <img src={getPathPrefix("/images/max-boards-reached-standalone.png")} alt="Canvas limit reached" />
          <div className={style.title} data-testid="having-fun-title">
            <p>Having fun, are we?</p>
          </div>
          <div className={style.subtitle}>
            <p>
              You have reached the <strong>3 canvas</strong> limit for this WorkCanvas free version. Upgrade to get
              unlimited canvases.
            </p>
          </div>
          <div className={style.buttonContainer} data-testid="upgrade-canvas-button">
            <StyledButton
              title="Upgrade Canvas"
              onClick={() => {
                tracking.reportGoogleAnalyticsConversion(consts.GA_CONVERSION_TYPES.UPGRADE_CTA);
                tracking.trackEvent(consts.TRACKING_CATEGORY.BILLING, "upgrade-cta-click", "upgrade-board-card");
                setShowUpgradeModal(true);
                setError(null);
              }}
            />
          </div>
        </div>
      </ModalContainer>
    );
  }

  useEffect(() => {
    if (showGridError) {
      switch (showGridError.type) {
        case "limitReached": {
          setError(showUpgradeRequiredModal(showGridError.data as number));
          break;
        }
        default: {
          break;
        }
      }
    }
  }, [showGridError]);

  useEffect(() => {
    if (user?.planInfo?.is_downgraded && user.isAdmin && !userTips.has(TipName.downgradeNotice)) {
      setShowDowngradeNoticeModal(true);
    }
    if (
      user?.planInfo?.is_downgraded &&
      user?.planInfo?.was_subscription_cancelled &&
      user.isAdmin &&
      !userTips.has(TipName.subscriptionCanceledNotice)
    ) {
      setShowSubscriptionCanceledNotice(true);
    }
    if (applyRestrictedLimitation && !userTips.has(TipName.restrictedUsersNotice) && shouldShoWRestrictedModal) {
      setShowRestrictedNoticeModal(true);
    }
  }, [
    userTips,
    applyRestrictedLimitation,
    shouldShoWRestrictedModal,
    user?.isAdmin,
    user?.planInfo?.is_downgraded,
    user?.planInfo?.was_subscription_cancelled,
  ]);

  //Hide the move canvas message after some interval
  useEffect(() => {
    let timeOut: NodeJS.Timeout;
    if (canvasMovingMessage) {
      timeOut = setTimeout(() => {
        setCanvasMovingMessage(null);
      }, 6000);
    }
    return () => timeOut && clearTimeout(timeOut);
  }, [canvasMovingMessage]);

  useEffect(() => {
    if (!user?.canInvite && adminModalTypeToShow === AdminModalType.inviteModal) {
      tracking.trackEvent(consts.TRACKING_CATEGORY.INVITE_ACTION, "no_permission_to_invite_modal", "invite-to-account");
    }
  }, [user?.canInvite, adminModalTypeToShow, AdminModalType.inviteModal]);

  useEffect(() => {
    if (
      selectedProjectId &&
      (selectedSection.type !== BoardSectionType.PROJECT || selectedSection.id !== selectedProjectId)
    ) {
      navigateToSection(BoardSectionType.PROJECT, selectedProjectId);
    } else if (
      selectedTeamId &&
      (selectedSection.type !== BoardSectionType.TEAM || selectedSection.id !== selectedTeamId)
    ) {
      navigateToSection(BoardSectionType.TEAM, selectedTeamId);
    }
  }, [selectedSection, selectedTeamId, selectedProjectId, teamsWithProjects]);

  useEffect(() => {
    if (isLocalStorageAvailable()) {
      setLocalStorageItem("selectedSection", selectedSection);
    }
  }, [selectedSection]);

  useEffect(() => {
    if (userTips.has(TipName.homePageRefactor)) {
      setShowHomePageRefactorTip(false);
    } else {
      setShowHomePageRefactorTip(true);
    }
  }, [userTips]);

  function onMyCanvasesClick() {
    navigateToSection(BoardSectionType.MY_BOARDS);
  }

  function onRecentCanvasesClick() {
    navigateToSection(BoardSectionType.RECENT);
  }

  function onSharedWithMeClick() {
    navigateToSection(BoardSectionType.SHARED_WITH_ME);
  }

  function onEditSeats() {
    setAdminModalTypeToShow(AdminModalType.EditSeatsModal);
  }

  function showNoPermissionToCreateModal() {
    if (shouldShoWRestrictedModal) {
      return <RestrictedUserModal trackingEvent={"home"} onDismiss={() => setError(null)} />;
    }
    return (
      <Modal dimBackground={true} onClickBackground={() => setError(null)}>
        <div className={style.contactYourAdmin}>
          <CollaborationHeader
            title={`You are not an editor in this ${instanceType}`}
            subtitle={`You are viewer in this ${instanceType}, to have editing permissions and create more canvases, please request access from the ${instanceType} owner.`}
            onDismiss={() => setError(null)}
          />
          <StyledButton title={"Got it"} customStyle={{ width: "107px" }} onClick={() => setError(null)} />
        </div>
      </Modal>
    );
  }

  function getTeamAndProjectForNewCanvas({ teamId, projectId }: { projectId?: number | null; teamId?: number | null }) {
    if (projectId === null && teamId === null) {
      return { teamId: null, projectId: null };
    }
    const instanceType = projectId ? InstanceType.Project : InstanceType.Team;

    if (user && instance && !canCreateInstance(user, instance as Team, instanceType)) {
      setError(showNoPermissionToCreateModal());
      setIsBusy(false);
    }
    const finalTeamId = teamId ?? null;
    const finalProjectId = projectId ?? null;
    return { teamId: finalTeamId, projectId: finalProjectId };
  }

  async function handleCreateNewBoard(
    template: Omit<CanvasTemplate, "data"> & { data?: Record<string, unknown> },
    boardParentInstance: { teamId: number | null; projectId: number | null } | null
  ) {
    setIsBusy(true);
    if (template.upgradeRequired) {
      setModalDeepLink({ modalType: ModalType.PreviewTemplate, id: template.id, name: template.name });
      setIsBusy(false);
      return;
    }

    const maxAllowedCanvases = Number.parseInt(maxAllowedCanvasesString);

    if (boards.length >= maxAllowedCanvases) {
      setError(showUpgradeRequiredModal(maxAllowedCanvases));
      setIsBusy(false);
      return;
    }
    try {
      if (boardParentInstance) {
        const { teamId, projectId } = boardParentInstance;
        const userName = user?.name.split(" ")[0];
        const name = template.id == BlankCanvasId ? (userName ? `${userName}'s Canvas` : "New Canvas") : template.name;
        const boardState = BoardState.templateNotSetUp;
        const board = await createBoardNew(name, boardState, template.id, projectId, teamId, dispatch, true);
        if (board) {
          tracking.trackAnalyticsEvent("new_canvas", {
            category: consts.TRACKING_CATEGORY.CANVAS_ACTION,
            templateName: template.name,
          });

          window.sessionStorage.setItem(consts.SESSION_STORAGE_KEYS.FIRST_ACCESS, "true");
          if (template.data) {
            window.sessionStorage.setItem(
              consts.SESSION_STORAGE_KEYS.CREATED_TEMPLATE_DATA + board.documentId,
              JSON.stringify(template.data)
            );
          }
          board.createdFromTemplateId
            ? router.push(`/d/${board.documentId}`)
            : router.push(`/d/${board.documentId}#show=templateLibrary`);
        }
      }
    } catch (error: unknown) {
      if ((error as { response?: { status: number; data: { maxBoardsAllowed: number } } })?.response?.status === 406) {
        const typedError = error as { response: { data: { maxBoardsAllowed: number } } };
        setError(showUpgradeRequiredModal(typedError.response.data.maxBoardsAllowed));
      }
    } finally {
      setIsBusy(false);
    }
  }

  function createNewBoard({
    template,
    inputProjectId = null,
    inputTeamId = null,
    source,
  }: {
    template: Omit<CanvasTemplate, "data"> & { data?: Record<string, unknown> };
    inputProjectId: number | null;
    inputTeamId: number | null;
    source: string;
  }) {
    tracking.trackAnalyticsEvent("create-canvas-clicked", {
      category: consts.TRACKING_CATEGORY.CANVAS_ACTION,
      templateName: template?.name,
      source: source,
    });

    const boardParentInstance = getTeamAndProjectForNewCanvas({ teamId: inputTeamId, projectId: inputProjectId });

    if ((boardParentInstance.teamId === null && boardParentInstance.projectId === null) || members.length === 1) {
      handleCreateNewBoard(template, boardParentInstance);
    } else {
      setApproveModalType(boardParentInstance?.projectId ? InstanceType.Project : InstanceType.Team);
      setCreateCanvasProps({
        template,
        inputProjectId: boardParentInstance?.projectId,
        inputTeamId: boardParentInstance?.teamId,
      });
    }
  }

  function onChooseTemplate({ template }: { template: CanvasTemplate }) {
    setModalDeepLink({ modalType: RESET });
    tracking.trackEvent(consts.TRACKING_CATEGORY.ADMIN_ACTION, "canvas_template_selected_main_screen", template.id);
    createNewBoard({
      template,
      inputProjectId: selectedProjectId,
      inputTeamId: selectedTeamId,
      source: "template-library",
    });
  }

  function handleModalAction(action: ModalAction) {
    if (action) {
      switch (action.type) {
        case "chooseTemplate": {
          onChooseTemplate(action.payload);
          break;
        }
      }
    }
  }

  function onInviteClick(title?: string) {
    const underScoredTitle = title ? `${title.toLowerCase().replaceAll(" ", "_")}_` : "";
    title && setInviteSource(underScoredTitle);
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.ADMIN_ACTION,
      `${underScoredTitle}invite_button_clicked`,
      "invite_type",
      "account"
    );
    if (shouldShoWRestrictedModal) {
      setError(showNoPermissionToCreateModal());
    } else {
      setAdminModalTypeToShow(AdminModalType.inviteModal);
    }
  }

  function handleNoPermissionToCreate() {
    setError(showNoPermissionToCreateModal());
    setIsBusy(false);
  }

  function onCloseConfirmCreateCanvasModal() {
    setApproveModalType(null);
  }

  return (
    <div
      id={"boards-container"}
      className={classNames(style.container, style.containerMonday, {
        [style.containerWithBanner]: isBannerNotificationOn,
      })}
    >
      <AppHeader
        isBoardHeader={false}
        showDowngradedNotification={user?.planInfo?.is_downgraded}
        onInviteClick={onInviteClick}
        onRestrictedClick={() => setError(showNoPermissionToCreateModal())}
      >
        <StyledButton
          title="Invite"
          icon={getPathPrefix("/images/plus-icon.svg")}
          onClick={() => onInviteClick("invite-people-header-button")}
          ref={inviteToAccountRef}
        />
      </AppHeader>
      {adminModalTypeToShow !== null && (
        <Modal dimBackground={true}>
          {adminModalTypeToShow === AdminModalType.EditSeatsModal ? (
            <EditSeatsModal onDismiss={() => setAdminModalTypeToShow(null)} />
          ) : (
            <InviteModalNew
              instance={user?.account as Account}
              type={InstanceType.Account}
              onDismiss={() => setAdminModalTypeToShow(null)}
              onEditSeats={onEditSeats}
              inviteMode={InviteMode.Edit}
              source={inviteSource}
            />
          )}
        </Modal>
      )}
      <div
        className={style.contentBackground}
        style={{
          transform: "none",
        }}
      />
      <div className={classNames(style.left, { [style.leftWithBanner]: isBannerNotificationOn && isMondayUser })}>
        {
          <>
            <AccountSwitcher accountName={user?.account?.name ?? " "} />
            <SideMenuNew
              selectedTeamId={selectedTeamId}
              setSelectedTeam={(team) => {
                navigateToSection(BoardSectionType.TEAM, team.id);
              }}
              selectedProjectId={selectedProjectId}
              setSelectedProject={(project) => {
                navigateToSection(BoardSectionType.PROJECT, project.id);
              }}
              selectedSection={selectedSection}
              createNewBoard={createNewBoard}
              onEditSeats={onEditSeats}
              onMyCanvasesClick={onMyCanvasesClick}
              onRecentCanvasesClick={onRecentCanvasesClick}
              onSharedWithMeClick={onSharedWithMeClick}
              handleNoPermissionToCreate={handleNoPermissionToCreate}
              setShowRestrictedModal={setShowRestrictedNoticeModal}
              setShowTemplatesModal={(show: boolean) =>
                show
                  ? setModalDeepLink({ modalType: ModalType.TemplateLibrary })
                  : setModalDeepLink({ modalType: ModalType.None })
              }
            />
          </>
        }
      </div>
      <div
        className={style.searchTemplates}
        style={{
          pointerEvents: "auto",
        }}
      >
        <SearchSection />
      </div>
      <div
        className={style.templates}
        style={{
          transform: "none",
          pointerEvents: isBusy ? "none" : "auto",
        }}
      >
        {isReady && (
          <TemplatesSectionNew
            onCreateBoard={(template) =>
              createNewBoard({
                template,
                inputProjectId: selectedProjectId,
                inputTeamId: selectedTeamId,
                source: "template-section",
              })
            }
            onPreviewClicked={(template) => {
              setModalDeepLink({ modalType: ModalType.PreviewTemplate, id: template.id, name: template.name });
            }}
            setShowTemplatesModal={(show) =>
              show
                ? setModalDeepLink({ modalType: ModalType.TemplateLibrary })
                : setModalDeepLink({ modalType: ModalType.None })
            }
          />
        )}
      </div>
      <div
        className={style.contentArea}
        style={{
          transform: "none",
          pointerEvents: isBusy ? "none" : "auto",
        }}
      >
        {isReady && (
          <div
            className={style.contentArea}
            style={{
              transform: "none",
              pointerEvents: isBusy ? "none" : "auto",
            }}
          >
            <BoardsBySection
              section={selectedSection}
              onSelected={onSelected}
              createNewBoard={createNewBoard}
              onDuplicateError={(number) => setError(showUpgradeRequiredModal(number))}
              setCanvasToMove={setCanvasToMove}
              selectedTeamId={selectedTeamId}
              selectedProjectId={selectedProjectId}
            />
          </div>
        )}
      </div>
      <div className={style.footer}>
        <div className={style.logo}>
          <span>By</span>
          <img src={getPathPrefix("/images/monday-logo.svg")} alt="" />
        </div>
        <a href="https://monday.com/l/legal/tos/">Terms of use</a>
        <a href="https://monday.com/l/privacy/privacy-policy/">Privacy policy</a>
      </div>
      <ModalManager
        setIsLoading={setIsBusy}
        handleModalAction={handleModalAction}
        onShowUpgradeModal={() => {
          setModalDeepLink({ modalType: RESET });
          setShowUpgradeModal(true);
        }}
      />
      {error && <Modal dimBackground={true}>{error}</Modal>}
      {showUpgradeModal && (
        <Checkout
          user={user}
          onDismiss={() => {
            setShowUpgradeModal(false);
          }}
        />
      )}
      {canvasToMove && (
        <Modal dimBackground={true} onDismissOnEscape={() => setCanvasToMove(null)}>
          <MoveCanvasModalNew
            board={canvasToMove}
            onDismiss={() => setCanvasToMove(null)}
            setCanvasMovingMessage={setCanvasMovingMessage}
          />
        </Modal>
      )}
      {canvasMovingMessage && (
        <MoveCanvasToast content={canvasMovingMessage} onClose={() => setCanvasMovingMessage(null)} />
      )}
      {showDowngradeNoticeModal && (
        <UpgradeModalAfterDowngrade
          showUpgradeModalAfterDowngrade={setShowDowngradeNoticeModal}
          setShowUpgradeModal={setShowUpgradeModal}
          type="downgrade"
        />
      )}
      {showSubscriptionCanceledNotice && (
        <UpgradeModalAfterDowngrade
          showUpgradeModalAfterDowngrade={setShowSubscriptionCanceledNotice}
          setShowUpgradeModal={setShowUpgradeModal}
          type="subscriptionCanceled"
        />
      )}
      {showRestrictedNoticedModal && (
        <RestrictedUserNoticeModal
          trackingEvent={"home"}
          onDismiss={() => {
            markTipSeen(TipName.restrictedUsersNotice);
            setShowRestrictedNoticeModal(false);
          }}
        />
      )}
      {showAccountMembers && (
        <Modal>
          <AccountMembers
            title={"View account members"}
            onDismiss={() => setShowAccountMembers(false)}
            onEditSeats={onEditSeats}
          />
        </Modal>
      )}
      {approveModalType && (
        <Modal
          dimBackground={true}
          onClickBackground={onCloseConfirmCreateCanvasModal}
          onDismissOnEscape={onCloseConfirmCreateCanvasModal}
        >
          <ConfirmCreateCanvasModal
            type={approveModalType}
            onCancel={onCloseConfirmCreateCanvasModal}
            onCreate={() => {
              createCanvasProps &&
                handleCreateNewBoard(createCanvasProps.template, {
                  projectId: createCanvasProps.inputProjectId ?? null,
                  teamId: createCanvasProps.inputTeamId ?? null,
                });
              onCloseConfirmCreateCanvasModal();
            }}
          />
        </Modal>
      )}
      {showHomePageRefactorTip && (
        <Modal dimBackground={true}>
          <HomePageRefactorAnnouncement
            onDismiss={() => {
              setShowHomePageRefactorTip(false);
            }}
          />
        </Modal>
      )}
      <div id={PortalID.Floaters} />
      <div id={PortalID.Tooltips} />
      <WalkthroughTipPresenter
        tipName={TipName.walkthroughInviteToAccount}
        attachToRef={inviteToAccountRef}
        side="top"
        type={WalkthroughTipType.HomePage}
      />
    </div>
  );
}
