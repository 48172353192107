import { useProjectsCache } from "frontend/hooks/caching/use-projects-cache";
import { useTeamsCache } from "frontend/hooks/caching/use-teams-cache";
import { useState, useEffect } from "react";
import { Project } from "shared/datamodel/schemas/project";
import { Team, TeamWithProjects } from "shared/datamodel/schemas/team";
import { ProjectCellNew } from "./projects-cell-new";
import { CanvasTemplate } from "shared/datamodel/schemas";
import style from "./projects-list.module.css";

export default function ProjectList({
  team,
  selectedProjectId,
  handleProjectSelection,
  onEditSeats,
  createNewBoard,
  onDeleteSelectedProject,
  handleNewProjectClick,
}: {
  team: TeamWithProjects;
  selectedProjectId: number | null;
  handleProjectSelection: (project: Project) => void;
  onEditSeats: () => void;
  createNewBoard: ({
    template,
    inputProjectId,
    inputTeamId,
    source,
  }: {
    template: CanvasTemplate;
    inputProjectId: number | null;
    inputTeamId: number | null;
    source: string;
  }) => void;
  onDeleteSelectedProject: () => void;
  handleNewProjectClick: (teamId: number) => void;
}) {
  const [projects, setProjects] = useState<Project[]>([]);
  const [parentTeam, setParentTeam] = useState<Team | null>(null);
  const [showEmptyState, setShowEmptyState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getProjects } = useProjectsCache();
  const { getTeams } = useTeamsCache();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setIsLoading(true);
        const [fetchedProjects, parentTeams] = await Promise.all([
          getProjects(team.projects.map((project) => String(project.id))),
          getTeams([String(team.id)]),
        ]);
        setProjects(fetchedProjects as Project[]);
        setParentTeam(parentTeams[0]);
        if (fetchedProjects.length === 0) {
          setShowEmptyState(true);
        }
        setIsLoading(false);
      } catch {
        setProjects([]);
        setShowEmptyState(true);
        setParentTeam(null);
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, [team.id, team.projects, getProjects, getTeams]);

  return (
    <div>
      {parentTeam &&
        projects.map((project) => (
          <ProjectCellNew
            key={`project-${project.id}`}
            project={project}
            parentTeam={parentTeam}
            isSelected={selectedProjectId === project.id}
            onClick={() => handleProjectSelection(project)}
            onEditSeats={onEditSeats}
            createNewBoard={createNewBoard}
            onDeleteSelectedProject={onDeleteSelectedProject}
          />
        ))}
      {showEmptyState && (
        <div className={style.emptyState}>
          <p className={style.emptyStateText}>This team has no projects</p>
          <div className={style.createProjectText} onClick={() => handleNewProjectClick(team.id)}>
            + Create a project
          </div>
        </div>
      )}
      {isLoading && (
        <div className={style.loading}>
          <div className={style.emptyStateText}>
            Loading projects
            <div className={style.dotTyping}></div>
          </div>
        </div>
      )}
    </div>
  );
}
