import React from "react";

export const TemplatesIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <rect x="1" y="1" width="13.5" height="13.5" rx="1.5" stroke="#113357" strokeWidth="1.2" />
      <path d="M4.85742 4.85715L4.85742 14.5" stroke="#113357" strokeWidth="1.2" strokeLinecap="round" />
      <path d="M14.5 4.85715L1 4.85715" stroke="#113357" strokeWidth="1.2" strokeLinecap="round" />
    </svg>
  );
};
