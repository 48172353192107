import { useTeamsCache } from "frontend/hooks/caching/use-teams-cache";
import CreateCollaborationModal from "frontend/modals/collaboration/create-collaboration-modal";
import { CollaborationType, InviteMode } from "state-atoms";
import Modal from "frontend/modal/modal";
import RestrictedUserModal from "frontend/modals/restricted-user-modal";
import { useState } from "react";
import InviteModalNew from "frontend/modals/invite-modal-new";
import { InstanceType } from "shared/datamodel/schemas/ability";
import { Team, TeamWithProjects } from "shared/datamodel/schemas/team";
import AppLoader from "frontend/loader/app-loader";

export function CreateTeam({
  onEditSeats,
  onDismiss,
  setSelectedTeam,
}: {
  onEditSeats: () => void;
  onDismiss: () => void;
  setSelectedTeam: (team: TeamWithProjects) => void;
}) {
  const [teamName, setTeamName] = useState("");
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(true);
  const [showInviteTeammatesModal, setShowInviteTeammatesModal] = useState(false);
  const [showRestrictedModal, setShowRestrictedModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [createdTeam, setCreatedTeam] = useState<Team | null>(null);
  const { createTeam: handleCreateTeam } = useTeamsCache();

  async function createTeam(name: string) {
    setIsLoading(true);
    const team = await handleCreateTeam({ name });
    setIsLoading(false);
    if (team) {
      setCreatedTeam(team);
      setShowInviteTeammatesModal(true);
      setTeamName(name);
      setSelectedTeam(team as TeamWithProjects);
    } else {
      setShowRestrictedModal(true);
    }
  }

  return (
    <>
      {showCreateTeamModal && (
        <Modal dimBackground={true}>
          <CreateCollaborationModal
            onDismiss={() => {
              setShowCreateTeamModal(false);
              onDismiss();
            }}
            onCreateTeam={(name: string) => {
              createTeam(name);
            }}
            type={CollaborationType.Team}
            subtitle={"Name your team before invite your teammates to join."}
            inputPlaceholder={"Enter team name"}
          />
        </Modal>
      )}
      {showInviteTeammatesModal && createdTeam && (
        <Modal dimBackground={true}>
          <InviteModalNew
            name={teamName}
            onDismiss={onDismiss}
            inviteMode={InviteMode.Create}
            onEditSeats={onEditSeats}
            source="create-team"
            instance={createdTeam}
            type={InstanceType.Team}
          />
        </Modal>
      )}
      {showRestrictedModal && (
        <RestrictedUserModal trackingEvent={"home"} onDismiss={() => setShowRestrictedModal(false)} />
      )}
      {isLoading && <AppLoader />}
    </>
  );
}
