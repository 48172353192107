import classNames from "classnames";
import style from "./side-menu-section.module.css";
import tracking from "frontend/tracking";
import consts from "shared/consts";

export function SideMenuSection({
  name,
  icon,
  onClick,
  isSelected,
}: {
  name: string;
  icon: () => JSX.Element;
  onClick: () => void;
  isSelected: boolean;
}) {
  return (
    <div
      onClick={() => {
        onClick();
        tracking.trackAnalyticsEvent("side-menu-section-clicked", {
          category: consts.TRACKING_CATEGORY.SIDE_MENU,
          section: name,
        });
      }}
      className={style.container}
    >
      <div className={classNames(style.content, { [style.selected]: isSelected })}>
        {icon()}
        {name}
      </div>
    </div>
  );
}
