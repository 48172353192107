import React from "react";

export const LinkIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
      <path
        d="M5.66602 7.82531C5.90418 8.05151 6.18846 8.23122 6.50219 8.3539C6.81593 8.47658 7.15281 8.53977 7.49312 8.53977C7.83343 8.53977 8.17032 8.47658 8.48405 8.3539C8.79778 8.23122 9.08206 8.05151 9.32023 7.82531L12.2436 5.10486C12.7282 4.65392 13.0004 4.04231 13.0004 3.40459C13.0004 2.76686 12.7282 2.15525 12.2436 1.70431C11.759 1.25337 11.1018 1.00003 10.4165 1.00003C9.73119 1.00003 9.07396 1.25337 8.58938 1.70431L8.22396 2.04436"
        stroke="#113357"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33439 5.48483C8.09622 5.25863 7.81195 5.07893 7.49821 4.95624C7.18448 4.83356 6.84759 4.77037 6.50729 4.77037C6.16698 4.77037 5.83009 4.83356 5.51636 4.95624C5.20262 5.07893 4.91835 5.25863 4.68018 5.48483L1.75681 8.20528C1.27223 8.65622 1 9.26783 1 9.90556C1 10.5433 1.27223 11.1549 1.75681 11.6058C2.24139 12.0568 2.89862 12.3101 3.58392 12.3101C4.26921 12.3101 4.92644 12.0568 5.41102 11.6058L5.77644 11.2658"
        stroke="#113357"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
