import React from "react";

export const MenuIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="2" height="10" viewBox="0 0 2 10" fill="none">
      <circle cx="1" cy="1" r="1" fill="currentColor" />
      <circle cx="1" cy="5" r="1" fill="currentColor" />
      <circle cx="1" cy="9" r="1" fill="currentColor" />
    </svg>
  );
};
