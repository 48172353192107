import React from "react";

export const TrashIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" viewBox="0 0 15 17" fill="none">
      <path d="M1 4.33398H14.3333" stroke="#FF6A5F" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6 7.66602V12.666" stroke="#FF6A5F" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.33301 7.66602V12.666"
        stroke="#FF6A5F"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.83301 4.33398L2.66634 14.334C2.66634 14.776 2.84194 15.1999 3.1545 15.5125C3.46706 15.8251 3.89098 16.0007 4.33301 16.0007H10.9997C11.4417 16.0007 11.8656 15.8251 12.1782 15.5125C12.4907 15.1999 12.6663 14.776 12.6663 14.334L13.4997 4.33398"
        stroke="#FF6A5F"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.16699 4.33333V1.83333C5.16699 1.61232 5.25479 1.40036 5.41107 1.24408C5.56735 1.0878 5.77931 1 6.00033 1H9.33366C9.55467 1 9.76663 1.0878 9.92291 1.24408C10.0792 1.40036 10.167 1.61232 10.167 1.83333V4.33333"
        stroke="#FF6A5F"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
