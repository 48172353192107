import React, { useRef, useState } from "react";
import style from "./board-card-new.module.css";
import { useOnClickOutside } from "usehooks-ts";
import { BoardCardWrapperNew } from "./board-card-wrapper-new";
import { Board, MinimalBoard } from "shared/datamodel/schemas/board";
import { format, register } from "timeago.js";
import EditableText, { EditableTextStyle } from "frontend/ui-components/editable-text";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { useAccountReps } from "frontend/hooks/use-account-reps";
import { useAtom, useAtomValue } from "jotai";
import { userAtom } from "state-atoms/users-atoms";
import { isBusyAtom } from "state-atoms/general-atoms";
import { InstanceType } from "shared/datamodel/schemas/ability";
import useAbility from "frontend/hooks/use-ability";
import RestrictedUserModal from "frontend/modals/restricted-user-modal";
import { getPathPrefix } from "../utils/getPathPrefix";
import { TrashIcon } from "frontend/icons/trash";
import { LinkIcon } from "frontend/icons/link";
import { DuplicateIcon } from "frontend/icons/duplicate";
import { MoveIcon } from "frontend/icons/move";
import { DotIcon } from "frontend/icons/dot";
import { useBoardsCache } from "frontend/hooks/caching/use-boards-cache";
import { customLocale } from "frontend/utils/date-utils";
import { MenuHorizontalIcon } from "frontend/icons/menu-horizontal";
import { Floater } from "frontend/ui-components/floaters/floater";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";
import { IllustrationIcon } from "frontend/icons/board-card/illustration";

register("custom-short", customLocale);

export function BoardCardNew({
  onClick,
  board,
  parentName,
  showDynamicPreview,
  onDuplicateError,
  boardIndex,
  setCanvasToMove,
}: {
  onClick?: (event: React.MouseEvent) => void;
  board: MinimalBoard;
  parentName: string;
  showDynamicPreview?: boolean;
  onDuplicateError: (maxAllowedBoards: number) => void;
  boardIndex: number;
  setCanvasToMove: (board: Board | null) => void;
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [showRestrictedModal, setShowRestrictedModal] = useState<boolean>(false);
  const [tooltip, setTooltip] = useState<string | null>(null);
  const { updateBoardLastChanged } = useAccountReps();
  const { canPerformAnyActionNew } = useAbility();
  const { removeBoard, updateBoard, duplicateBoard } = useBoardsCache();

  const user = useAtomValue(userAtom);
  const [, setBusy] = useAtom(isBusyAtom);

  const menuButtonRef = useRef(null);
  const shouldShoWRestrictedModal = user && !canPerformAnyActionNew(user, board as Board, InstanceType.Board);
  const menuRef = useRef<HTMLDivElement>(null);
  const parentNameRef = useRef<HTMLDivElement>(null);

  let href;
  if (process.env.NEXT_BRANCH_PATH) {
    href = `${process.env.APP_URL}${process.env.NEXT_BRANCH_PATH}/d/${board.documentId}`;
  } else {
    href = `${process.env.APP_URL}/d/${board.documentId}`;
  }

  function menuClicked(e: React.MouseEvent) {
    e.stopPropagation();
    setShowMenu(!showMenu);
  }

  useOnClickOutside(menuRef, () => {
    setShowMenu(false);
  });

  const renderBoardMenu = () => {
    return (
      <>
        <Floater
          relativeTo={menuButtonRef}
          side={"bottom"}
          ref={menuRef}
          extraStyle={{
            zIndex: "1",
            filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.20))",
          }}
        >
          <div className={style.canvasMenu}>
            <span data-testid="copy-link" className={style.item} onClick={copyCanvasClicked}>
              <LinkIcon />
              {"Copy link"}
            </span>
            {!board.isReadOnly && (
              <span data-testid="duplicate" className={style.item} onClick={duplicateClicked}>
                <DuplicateIcon />
                {"Duplicate canvas"}
              </span>
            )}
            {board.isOwner && (
              <>
                <span data-testid="move canvas" className={style.item} onClick={moveCanvasClicked}>
                  <MoveIcon />
                  {"Move canvas"}
                </span>
              </>
            )}
            {board.isOwner && (
              <span data-testid="delete" className={style.item} onClick={deleteClicked} style={{ color: "#FF6A5F" }}>
                <TrashIcon />
                {"Delete canvas"}
              </span>
            )}
          </div>
        </Floater>
      </>
    );
  };

  function copyCanvasClicked(e: React.MouseEvent) {
    e.stopPropagation();
    navigator.clipboard.writeText(`${location.host}/d/${board.documentId}`);
    setShowMenu(false);
  }

  function deleteClicked(e: React.MouseEvent) {
    tracking.trackAnalyticsEvent("delete_canvas", {
      category: consts.TRACKING_CATEGORY.CANVAS_ACTION,
    });
    e.stopPropagation();
    removeBoard(board);
    setShowMenu(false);
  }

  async function duplicateClicked(e: React.MouseEvent) {
    tracking.trackAnalyticsEvent("duplicate_canvas", {
      category: consts.TRACKING_CATEGORY.CANVAS_ACTION,
    });
    e.stopPropagation();
    setShowMenu(false);
    if (shouldShoWRestrictedModal) {
      setShowRestrictedModal(true);
      return;
    }
    if (user && user.repsToken) {
      setBusy(true);
      duplicateBoard({ documentId: board.documentId, userId: user.id, userRepsToken: user.repsToken })
        .catch((error) => {
          if (error?.response?.status === 406) {
            onDuplicateError(error.response.data.maxBoardsAllowed);
          }
        })
        .finally(() => setBusy(false));
    }
  }

  async function moveCanvasClicked(e: React.MouseEvent) {
    tracking.trackAnalyticsEvent("move canvas", {
      category: consts.TRACKING_CATEGORY.CANVAS_ACTION,
      property: board.documentId,
    });
    e.stopPropagation();
    setShowMenu(false);
    if (shouldShoWRestrictedModal) {
      setShowRestrictedModal(true);
      return;
    }
    setCanvasToMove(board as Board);
  }

  function handleTitleChange(newName: string) {
    const newBoard = { ...board, name: newName, updatedAt: new Date() };
    updateBoard(newBoard as Board);
    updateBoardLastChanged(board.documentId);
  }

  const backgroundImage = `/images/Illustration.png`;

  return (
    <>
      <BoardCardWrapperNew>
        <a href={href} className={style.link} onClick={onClick}>
          <div className={style.container} data-testid={boardIndex}>
            <div className={style.thumbnailContainer}>
              <div
                className={style.thumbnail}
                style={{
                  background: `url(${getPathPrefix(backgroundImage)})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: showDynamicPreview ? (board.thumbnail ? "contain" : "cover") : "100% 100%",
                  backgroundPosition: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <IllustrationIcon index={board.id % 8} />
              </div>
            </div>
            <div className={style.details} data-testid="board_card_details">
              <div className={style.boardName}>
                <EditableText
                  value={board.name}
                  editable={board.isOwner}
                  onChange={handleTitleChange}
                  showTooltipOnHover={true}
                  style={{
                    color: "#113357",
                    fontFamily: "Poppins",
                    fontSize: 14,
                    fontWeight: 500,
                    padding: 0,
                    margin: 0,
                    minWidth: 160,
                    maxWidth: 160,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  textStyle={EditableTextStyle.regular}
                />
              </div>
              <div className={style.subtitle}>
                {parentName.length > 0 ? (
                  <>
                    <span
                      ref={parentNameRef}
                      className={style.lastModified}
                      onMouseEnter={() => {
                        const isTextOverflowing =
                          parentNameRef.current &&
                          parentNameRef.current.scrollWidth > parentNameRef.current.clientWidth;
                        isTextOverflowing ? setTooltip(parentNameRef.current.innerText) : setTooltip(null);
                      }}
                      onMouseLeave={() => setTooltip(null)}
                    >{`${parentName} ${board.projectId ? "project" : "team"}`}</span>
                    <DotIcon />
                    {tooltip ? (
                      <Tooltip
                        relativeTo={parentNameRef}
                        side={"bottom"}
                        label={parentName}
                        customStyle={{
                          width: "fit-content",
                          backgroundColor: "#ebedf3",
                          color: "#113357",
                          padding: "0 12px",
                          boxShadow: "0px 2px 4px 0px #00000026",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          font: "400 16px Poppins",
                          whiteSpace: "nowrap",
                          height: 36,
                        }}
                        withArrow={false}
                      />
                    ) : null}
                  </>
                ) : null}
                <span className={style.lastModified}>Edited {format(board.updatedAt, "custom-short")}</span>
              </div>
            </div>
          </div>
        </a>
        <div className={style.menuIcon} onClick={menuClicked} ref={menuButtonRef} data-testid="board_menu">
          <MenuHorizontalIcon />
        </div>
        {showRestrictedModal && (
          <RestrictedUserModal trackingEvent={"home"} onDismiss={() => setShowRestrictedModal(false)} />
        )}
      </BoardCardWrapperNew>
      {showMenu && renderBoardMenu()}
    </>
  );
}
