import React, { useRef, useState } from "react";
import collaborationStyle from "./collaboration-modal-new.module.css";
import style from "./move-canvas-modal-new.module.css";
import StyledButton, { ButtonSize, ButtonStyle } from "frontend/ui-components/styled-button";
import DropdownPicker from "frontend/ui-components/picker/dropdown-picker";
import { TeamWithProjects } from "shared/datamodel/schemas/team";
import { Project } from "shared/datamodel/schemas/project";
import { Board, Permission } from "shared/datamodel/schemas";
import { InitialIconNew } from "frontend/ui-components/initial-icon-new";
import { useBoardsCache } from "frontend/hooks/caching/use-boards-cache";
import { useTeamsWithProjects } from "frontend/hooks/caching/use-teams-with-projects";
import { find } from "rambda";
import { MeIcon } from "frontend/icons/me";

enum Step {
  chooseTeamProjectModal,
  approvePermissionChangeModal,
}

export default function MoveCanvasModalNew({
  board,
  onDismiss,
  setCanvasMovingMessage,
}: {
  board: Board;
  onDismiss: () => void;
  setCanvasMovingMessage: (movingDetails: JSX.Element) => void;
}) {
  const [step, setStep] = useState<Step>(Step.chooseTeamProjectModal);
  const [selectedTeam, setSelectedTeam] = useState<TeamWithProjects | null>(null);
  const [selectedProject, setSelectedProject] = useState<Pick<
    Project,
    "id" | "name" | "permission" | "isOwner"
  > | null>(null);

  const modalRef = useRef(null);
  const { moveBoard } = useBoardsCache();
  const { teamsWithProjects } = useTeamsWithProjects();

  const editableTeamsForUser = teamsWithProjects.filter(
    (team) => team.isOwner || team.permission === Permission.editor
  );
  const editableProjectsForUser =
    selectedTeam?.projects?.filter((project) => project.isOwner || project.permission === Permission.editor) ?? [];

  const myCanvasesAsTeam = { id: -1, name: "My Canvases" } as TeamWithProjects;

  const getName = (board: Board) => {
    if (board.projectId) {
      const team = find((team) => team.id === board.teamId, teamsWithProjects);
      return find((project) => project.id === board.projectId, team?.projects ?? [])?.name;
    }
    if (board.teamId) {
      return find((team) => team.id === board.teamId, teamsWithProjects)?.name;
    } else {
      return myCanvasesAsTeam.name;
    }
  };
  const handleMoveCanvas = async () => {
    let teamId, projectId;
    if (selectedTeam?.id === -1) {
      teamId = null;
      projectId = null;
    } else {
      teamId = selectedTeam?.id ?? null;
      projectId = selectedProject?.id ?? null;
    }
    const newBoard = {
      ...board,
      updatedAt: new Date(),
      teamId,
      projectId,
    };
    await moveBoard(newBoard, board);
    const source = getName(board);
    const destination = getName(newBoard);
    const message = (
      <>
        <b>{`"${board.name}"`}</b>
        {" canvas has been moved from "}
        <b>{`${source}`}</b> to <b> {`${destination}`}</b>
      </>
    );
    setCanvasMovingMessage(<span className={style.toastText}>{message}</span>);
  };

  function renderCloseIcon() {
    return (
      <svg
        className={collaborationStyle.closeIcon}
        onClick={onDismiss}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="close-template-modal"
      >
        <path
          d="M6.36407 6.36396L19.092 19.0919M19.092 6.36396L6.36407 19.0919"
          stroke="#848199"
          strokeWidth="1.74"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function optionRenderer(team: TeamWithProjects) {
    if (team.id === -1) {
      return {
        element: (
          <div className={style.optionRenderer}>
            <span className={style.myCanvasesIcon}>
              <MeIcon />
            </span>
            {team.name}
          </div>
        ),
        enabled: true,
      };
    }
    return {
      element: (
        <div className={style.optionRenderer}>
          <InitialIconNew text={team.name} list={teamsWithProjects} />
          {team.name}
        </div>
      ),
      enabled: true,
    };
  }

  function renderChooseTeamProjectModal() {
    return (
      <div className={style.container} ref={modalRef}>
        {renderCloseIcon()}
        <p className={style.title}>{`Move "${board.name}" canvas to:`}</p>
        <div>
          <p className={style.subtitle}>This canvas will automatically be shared with all project members.</p>
          <p className={style.note}>Only projects where you have editing rights are shown.</p>
        </div>
        <div className={style.picker}>
          <DropdownPicker
            placeholder="Select team"
            pickerCustomStyle={{
              borderRadius: "6px",
            }}
            optionsPickerStyle={{ fontSize: 12, color: "#113357", borderRadius: "6px" }}
            options={[myCanvasesAsTeam, ...editableTeamsForUser]}
            titles={(team) => team.name}
            onChange={(team) => {
              setSelectedTeam(team);
            }}
            isSelected={(team) => team.id === selectedTeam?.id}
            enabled={true}
            optionRenderer={({ option: team }) => optionRenderer(team)}
          />
          <DropdownPicker
            placeholder="Select project"
            pickerCustomStyle={{
              borderRadius: "6px",
            }}
            optionsPickerStyle={{
              fontSize: 12,
              color: editableProjectsForUser.length > 0 ? "#113357" : "#848199",
              borderRadius: "6px",
            }}
            options={editableProjectsForUser}
            titles={(project) => project.name}
            onChange={(project) => {
              setSelectedProject(project);
            }}
            isSelected={(project) => project.id === selectedProject?.id}
            enabled={editableProjectsForUser.length > 0}
          />
        </div>
        <StyledButton
          title={`Move Canvas`}
          style={ButtonStyle.filled}
          size={ButtonSize.regular}
          onClick={() => setStep(Step.approvePermissionChangeModal)}
          enabled={!!selectedTeam}
          customStyle={{
            width: "127px",
            letterSpacing: "0.124px",
            borderRadius: "6px",
            color: "#FFF",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            alignSelf: "flex-end",
          }}
        />
      </div>
    );
  }

  function renderApprovePermissionChangeModal() {
    return (
      <div className={style.container} ref={modalRef}>
        {renderCloseIcon()}
        <p className={style.title}>{`Change who has access?`}</p>
        <p className={style.subtitle}>
          {`Everyone who can see ”${board.name}” will lose access to this canvas unless they have access to the target project or team.`}
        </p>
        <div className={style.buttons}>
          <StyledButton
            title={`Cancel`}
            style={ButtonStyle.filled}
            size={ButtonSize.regular}
            onClick={() => {
              onDismiss();
            }}
            enabled={!!selectedTeam}
            customStyle={{
              width: "127px",
              backgroundColor: "#F4F7FA",
              color: "#113357",
              letterSpacing: "0.124px",
              borderRadius: "6px",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          />
          <StyledButton
            title={`Move Canvas`}
            style={ButtonStyle.filled}
            size={ButtonSize.regular}
            onClick={() => {
              handleMoveCanvas();
              onDismiss();
            }}
            customStyle={{
              width: "127px",
              letterSpacing: "0.124px",
              borderRadius: "6px",
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          />
        </div>
      </div>
    );
  }

  function renderMoveCanvasStep() {
    switch (step) {
      case Step.chooseTeamProjectModal: {
        return renderChooseTeamProjectModal();
      }
      case Step.approvePermissionChangeModal: {
        return renderApprovePermissionChangeModal();
      }
    }
  }

  return renderMoveCanvasStep();
}
