import { useRef } from "react";
import style from "./account-switcher.module.css";
export function AccountSwitcher({ accountName }: { accountName: string }) {
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const firstLetter = accountName[0].toUpperCase();

  return (
    <div className={style.container} ref={menuButtonRef}>
      <div className={style.content}>
        <div className={style.thumbnail}>{firstLetter}</div>
        <span>{`${accountName} account`}</span>
      </div>
    </div>
  );
}
