import style from "./projects-cell-new.module.css";
import styles from "./rename-project-new.module.css";
import { useRef, useState } from "react";
import Modal from "frontend/modal/modal";
import { useHover, useOnClickOutside } from "usehooks-ts";
import { Project } from "shared/datamodel/schemas/project";
import LeaveCollaborationModalNew from "frontend/modals/collaboration/leave-collaboration-modal-new";
import DeleteCollaborationModalNew from "frontend/modals/collaboration/delete-collaboration-modal-new";
import classNames from "classnames";
import { Floater } from "frontend/ui-components/floaters/floater";
import InlineRename, { InlineRenameStyle } from "./inlineRename";
import { CollaborationType, InviteMode } from "state-atoms/teams-atoms";
import { useProjectsCache } from "frontend/hooks/caching/use-projects-cache";
import NoTeammatesToInviteModal from "frontend/modals/collaboration/project/no-teammates-to-invite-modal";
import InviteModalNew from "frontend/modals/invite-modal-new";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import ManageCollaborationMembers from "frontend/modals/collaboration/manage-collaboration-members";
import { InstanceType } from "shared/datamodel/schemas/ability";
import { Team } from "shared/datamodel/schemas/team";
import { ProjectIcon } from "frontend/icons/project";
import { InviteIcon } from "frontend/icons/invite";
import { MembersIcon } from "frontend/icons/members";
import { RenameIcon } from "frontend/icons/rename";
import { LeaveIcon } from "frontend/icons/leave";
import { TrashIcon } from "frontend/icons/trash";
import { MenuIcon } from "frontend/icons/menu";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";
import { BlankCanvasTemplate, CanvasTemplate, Permission } from "shared/datamodel/schemas";
import { CreateCanvasIcon } from "frontend/icons/create-canvas";

export function ProjectCellNew({
  parentTeam,
  project,
  isSelected,
  onClick,
  onEditSeats,
  createNewBoard,
  onDeleteSelectedProject,
}: {
  parentTeam: Team;
  project: Project;
  isSelected: boolean;
  onClick: () => void;
  onEditSeats: () => void;
  createNewBoard: ({
    template,
    inputProjectId,
    inputTeamId,
    source,
  }: {
    template: CanvasTemplate;
    inputProjectId: number | null;
    inputTeamId: number | null;
    source: string;
  }) => void;
  onDeleteSelectedProject: () => void;
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [focused, setFocused] = useState(false);
  const [projectName, setProjectName] = useState(project.name);
  const [showInviteProjectMembersModal, setShowInviteProjectMembersModal] = useState(false);
  const [showManageProjectModal, setShowManageProjectModal] = useState(false);
  const [showLeaveCollaborationModal, setShowLeaveCollaborationModal] = useState(false);
  const [showDeleteCollaborationModal, setShowDeleteCollaborationModal] = useState(false);
  const [showNoTeammatesToInviteMessage, setShowNoTeammatesToInviteMessage] = useState(false);
  const { updateProject: handleEditProjectName } = useProjectsCache();

  const menuButtonRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const ref = useRef<HTMLDivElement | null>(null);
  const hover = useHover(ref);
  const hoverMenu = useHover(menuButtonRef);

  useOnClickOutside(menuRef, () => {
    setShowMenu(false);
  });

  function menuClicked(event: React.MouseEvent) {
    event.stopPropagation();
    setShowMenu((show) => !show);
  }

  function renameClicked(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    setShowMenu(false);
    setFocused(true);
  }

  const projectMenuOwnerOptions = () => {
    return (
      <>
        <span
          data-testid="invite-members"
          className={style.item}
          onClick={() => {
            tracking.trackEvent(
              consts.TRACKING_CATEGORY.ADMIN_ACTION,
              "invite_to_project_button_clicked",
              "invite_type",
              "account"
            );
            setShowInviteProjectMembersModal(true);
            setShowMenu(false);
          }}
        >
          <InviteIcon />
          {"Invite members"}
        </span>
        <span
          data-testid="create canvas"
          className={style.item}
          onClick={() => {
            createNewBoard({
              template: BlankCanvasTemplate,
              inputProjectId: project.id,
              inputTeamId: parentTeam.id,
              source: "project-context-menu",
            });
            setShowMenu(false);
          }}
        >
          <CreateCanvasIcon />
          {"Create canvas"}
        </span>
        <span
          data-testid="Manage members"
          className={style.item}
          onClick={() => {
            setShowManageProjectModal(true);
            setShowMenu(false);
          }}
        >
          <MembersIcon />
          {"Project members"}
        </span>
        <span
          data-testid="Rename"
          className={style.item}
          onClick={(e) => {
            renameClicked(e);
            setShowMenu(false);
          }}
        >
          <RenameIcon />
          {"Rename project"}
        </span>
        <span
          data-testid="Leave"
          className={style.item}
          onClick={() => {
            setShowLeaveCollaborationModal(true);
            setShowMenu(false);
          }}
        >
          <LeaveIcon />
          {"Leave project"}
        </span>
        <span
          data-testid="Delete"
          className={classNames(style.item, style.delete)}
          onClick={() => {
            setShowDeleteCollaborationModal(true);
            setShowMenu(false);
          }}
        >
          <TrashIcon />
          {"Delete project"}
        </span>
      </>
    );
  };

  const projectMenuMemberOptions = () => {
    return (
      <>
        <span
          data-testid="Project members"
          className={style.item}
          onClick={() => {
            setShowManageProjectModal(true);
            setShowMenu(false);
          }}
        >
          <MembersIcon />
          {"Project members"}
        </span>
        {project.permission === Permission.editor && (
          <span
            data-testid="create canvas"
            className={style.item}
            onClick={() => {
              createNewBoard({
                template: BlankCanvasTemplate,
                inputProjectId: project.id,
                inputTeamId: parentTeam.id,
                source: "project-context-menu",
              });
              setShowMenu(false);
            }}
          >
            <CreateCanvasIcon />
            {"Create canvas"}
          </span>
        )}
        <span
          data-testid="Leave"
          className={style.item}
          onClick={() => {
            setShowLeaveCollaborationModal(true);
            setShowMenu(false);
          }}
        >
          <LeaveIcon />
          {"Leave project"}
        </span>
      </>
    );
  };

  const renderProjectMenu = () => {
    return (
      <Floater
        relativeTo={menuButtonRef}
        side={"bottom"}
        ref={menuRef}
        extraStyle={{
          zIndex: "1",
          filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.20))",
        }}
      >
        <div className={style.projectMenu}>
          {project.isOwner ? projectMenuOwnerOptions() : projectMenuMemberOptions()}
        </div>
      </Floater>
    );
  };

  const renderVisibleText = () => (
    <>
      {project && (
        <InlineRename
          value={projectName}
          textStyle={InlineRenameStyle.regular}
          style={{
            minWidth: "120px",
            maxWidth: "120px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          focus={focused}
          onEditing={(editing: boolean) => setFocused(editing)}
          onChange={(newName: string) => {
            setProjectName(newName);
            handleEditProjectName({ ...project, name: newName });
          }}
          styles={styles}
        />
      )}
    </>
  );

  return (
    <div
      key={project.id}
      ref={ref}
      className={classNames(style.cell, { [style.selectedProject]: isSelected, [style.rename]: focused })}
      onClick={onClick}
    >
      {hover && projectName.length > 13 && (
        <Tooltip
          side="right"
          label={projectName}
          relativeTo={ref}
          customStyle={{
            backgroundColor: "#DEE1EB",
            color: " #113357",
            fontSize: 12,
            fontWeight: 400,
            borderRadius: 4,
          }}
        />
      )}
      <div data-testid={projectName} className={style.projectName}>
        <ProjectIcon />
        {renderVisibleText()}
      </div>
      {hoverMenu && (
        <Tooltip
          side="top"
          label={"Settings"}
          relativeTo={menuButtonRef}
          customStyle={{
            backgroundColor: "#113357",
            borderRadius: 6,
            color: " #FFF",
            fontSize: 12,
            fontWeight: 300,
          }}
        />
      )}
      {(hover || showMenu) && !focused && (
        <div
          data-testid="projectmenuicon"
          onClick={(e) => menuClicked(e)}
          ref={menuButtonRef}
          className={classNames(style.menuIcon, { [style.selectedIcon]: isSelected })}
        >
          <MenuIcon />
        </div>
      )}
      {showMenu && renderProjectMenu()}
      {showNoTeammatesToInviteMessage && parentTeam && (
        <Modal dimBackground={true}>
          <NoTeammatesToInviteModal
            team={parentTeam}
            onDismiss={() => setShowNoTeammatesToInviteMessage(false)}
            title={`All team members are already invited to this project`}
            onEditSeats={onEditSeats}
          />
        </Modal>
      )}
      {showInviteProjectMembersModal && project && parentTeam && (
        <Modal dimBackground={true}>
          <InviteModalNew
            onDismiss={() => {
              setShowInviteProjectMembersModal(false);
            }}
            inviteMode={InviteMode.Edit}
            onEditSeats={onEditSeats}
            source="edit-project-members"
            instance={project}
            type={InstanceType.Project}
          />
        </Modal>
      )}
      {showManageProjectModal && project && (
        <Modal dimBackground={true} onClickBackground={() => setShowManageProjectModal(false)}>
          <ManageCollaborationMembers
            instanceId={project.id}
            type={InstanceType.Project}
            onDismiss={() => {
              setShowManageProjectModal(false);
            }}
            onEditSeats={onEditSeats}
          />
        </Modal>
      )}
      {showLeaveCollaborationModal && project && (
        <Modal dimBackground={true}>
          <LeaveCollaborationModalNew
            type={CollaborationType.Project}
            onDismiss={() => setShowLeaveCollaborationModal(false)}
            project={project}
            subtitle={`You will lose access to "${project.name}" project and its canvases.`}
          />
        </Modal>
      )}
      {showDeleteCollaborationModal && project && (
        <Modal dimBackground={true}>
          <DeleteCollaborationModalNew
            type={CollaborationType.Project}
            onDismiss={() => setShowDeleteCollaborationModal(false)}
            project={project}
            subtitle={`All canvases within this project will be permanently deleted. You can move canvases to another project or export them before deleting.`}
            isSelected={isSelected}
            onDeleteSelectedCollaboration={onDeleteSelectedProject}
          />
        </Modal>
      )}
    </div>
  );
}
