import { Crown } from "frontend/icons/crown";
import StyledButton, { ButtonSize, ButtonStyle } from "frontend/ui-components/styled-button";
import React from "react";
import style from "./responsive-template-card-new.module.css";
import cn from "classnames";
import { CanvasTemplateMinimal } from "shared/datamodel/schemas/canvas-template";

export enum TemplateCardStyle {
  small = "small",
  regular = "regular",
}

const buttonStyle = {
  width: 120,
  height: 26,
  borderRadius: 6,
  fontFamily: "Poppins",
  fontSize: 12,
  fontWeight: 400,
  letterSpacing: "0.132px",
};

export function ResponsiveTemplateCardNew({
  template,
  previewAvailable,
  onClick,
  onPreview,
  cardStyle = TemplateCardStyle.regular,
}: {
  template: CanvasTemplateMinimal;
  previewAvailable: boolean;
  onClick: (template: CanvasTemplateMinimal) => void;
  onPreview: (template: CanvasTemplateMinimal) => void;
  cardStyle?: TemplateCardStyle;
}) {
  const isSmall = cardStyle === TemplateCardStyle.small;

  const name = template.name;

  return (
    <div
      className={cn(style.gridContainer, { [style.small]: isSmall })}
      data-testid={template.name}
      onClick={() => onClick(template)}
    >
      <div className={cn(style.templateImage, { [style.small]: isSmall })}>
        <div className={style.imageContainer}>
          {template.thumbnailImageURL && <img src={template.thumbnailImageURL} alt={""} />}
        </div>
        {previewAvailable && (
          <div className={style.buttons}>
            <StyledButton
              title="Use Template"
              size={ButtonSize.small}
              style={ButtonStyle.filled}
              customStyle={buttonStyle}
            />
            <div className={style.previewButton}>
              <StyledButton
                title="Preview"
                size={ButtonSize.small}
                style={ButtonStyle.outline}
                onClick={(event) => {
                  event.stopPropagation();
                  onPreview(template);
                }}
                customStyle={{
                  ...buttonStyle,
                  background: "#D1E3FF",
                  color: "#1973FF",
                  border: "none",
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className={style.titleContainer}>
        <div className={style.templateTitle}>{name}</div>
        {template.upgradeRequired && (
          <div className={style.proBadge}>
            <Crown size={14} />
          </div>
        )}
      </div>
    </div>
  );
}
