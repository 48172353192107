import style from "./team-cell-new.module.css";
import styles from "./rename-team-new.module.css";
import { useRef, useState } from "react";
import { useHover, useOnClickOutside } from "usehooks-ts";
import { Team, TeamWithProjects } from "shared/datamodel/schemas/team";
import classNames from "classnames";
import { Floater } from "frontend/ui-components/floaters/floater";
import InlineRename, { InlineRenameStyle } from "./inlineRename";
import Modal from "frontend/modal/modal";
import { CollaborationType, InviteMode } from "state-atoms";
import ManageCollaborationMembers from "frontend/modals/collaboration/manage-collaboration-members";
import LeaveCollaborationModalNew from "frontend/modals/collaboration/leave-collaboration-modal-new";
import DeleteCollaborationModalNew from "frontend/modals/collaboration/delete-collaboration-modal-new";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";
import InviteModalNew from "frontend/modals/invite-modal-new";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { useTeamsCache } from "frontend/hooks/caching/use-teams-cache";
import { NewTeamOrProjectIcon } from "frontend/icons/new-team-or-project";
import { MenuIcon } from "frontend/icons/menu";
import { InviteIcon } from "frontend/icons/invite";
import { MembersIcon } from "frontend/icons/members";
import { RenameIcon } from "frontend/icons/rename";
import { LeaveIcon } from "frontend/icons/leave";
import { TrashIcon } from "frontend/icons/trash";
import { InstanceType } from "shared/datamodel/schemas/ability";
import AccountMembers from "frontend/modals/collaboration/account-members";
import { CreateCanvasIcon } from "frontend/icons/create-canvas";
import { BlankCanvasTemplate, CanvasTemplate, Permission } from "shared/datamodel/schemas";

export function TeamCellNew({
  team,
  isSelected,
  onClick,
  onEditSeats,
  handleNewProjectClick,
  createNewBoard,
  onDeleteSelectedTeam,
}: {
  team: TeamWithProjects;
  isSelected: boolean;
  onClick: () => void;
  onEditSeats: () => void;
  handleNewProjectClick: (teamId: number) => void;
  createNewBoard: ({
    template,
    inputProjectId,
    inputTeamId,
    source,
  }: {
    template: CanvasTemplate;
    inputProjectId: number | null;
    inputTeamId: number | null;
    source: string;
  }) => void;
  onDeleteSelectedTeam: () => void;
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [inlineRenameFocus, setInlineRenameFocus] = useState(false);
  const [teamName, setTeamName] = useState(team.name);
  const [showInviteTeammatesModal, setShowInviteTeammatesModal] = useState(false);
  const [showManageTeamModal, setShowManageTeamModal] = useState(false);
  const [showLeaveCollaborationModal, setShowLeaveCollaborationModal] = useState(false);
  const [showDeleteCollaborationModal, setShowDeleteCollaborationModal] = useState(false);
  const [fullTeam, setFullTeam] = useState<Team | null>(null);
  const [showTeamNameTooltip, setShowTeamNameTooltip] = useState(true);
  const { getTeams, updateTeam: handleEditTeamName } = useTeamsCache();

  const menuButtonRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const teamNameRef = useRef<HTMLDivElement | null>(null);
  const hoverTeamName = useHover(teamNameRef);

  const createProjectRef = useRef<HTMLDivElement | null>(null);
  const hoverCreateProject = useHover(createProjectRef);
  const hoverMenu = useHover(menuButtonRef);

  useOnClickOutside(menuRef, () => {
    setShowMenu(false);
    setShowTeamNameTooltip(true);
  });

  async function menuClicked(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    setShowMenu((show) => !show);
    if (!fullTeam) {
      try {
        const fetchedTeam = await getTeams([String(team.id)]);
        if (fetchedTeam?.[0]) {
          setFullTeam(fetchedTeam[0]);
        }
      } catch {
        setFullTeam(null);
      }
    }
  }

  function renameClicked(event: React.MouseEvent) {
    event.stopPropagation();
    setShowMenu(false);
    setInlineRenameFocus(true);
  }

  const renderTeamMenu = () => {
    return (
      <>
        <Floater
          relativeTo={menuButtonRef}
          side={"bottom"}
          ref={menuRef}
          extraStyle={{
            zIndex: "1",
            filter: "drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.20))",
          }}
        >
          <div className={style.teamMenu}>
            {team.isOwner ? (
              <>
                <span
                  data-testid="Invite members"
                  className={style.item}
                  onClick={() => {
                    tracking.trackEvent(
                      consts.TRACKING_CATEGORY.ADMIN_ACTION,
                      "team_picker_invite_to_team_button_clicked",
                      "invite_type",
                      "account"
                    );
                    setShowInviteTeammatesModal(true);
                    setShowMenu(false);
                    setShowTeamNameTooltip(false);
                  }}
                >
                  <InviteIcon />
                  {"Invite members"}
                </span>
                <span
                  data-testid="create canvas"
                  className={style.item}
                  onClick={() => {
                    createNewBoard({
                      template: BlankCanvasTemplate,
                      inputProjectId: null,
                      inputTeamId: team.id,
                      source: "team-context-menu",
                    });
                    setShowMenu(false);
                  }}
                >
                  <CreateCanvasIcon />
                  {"Create canvas"}
                </span>
                <span
                  data-testid="Manage team"
                  className={style.item}
                  onClick={() => {
                    setShowManageTeamModal(true);
                    setShowTeamNameTooltip(false);
                    setShowMenu(false);
                  }}
                >
                  <MembersIcon />
                  {"Team members"}
                </span>
                <span
                  data-testid="Rename"
                  className={style.item}
                  onClick={(e) => {
                    renameClicked(e);
                    setShowMenu(false);
                    setShowTeamNameTooltip(false);
                  }}
                >
                  <RenameIcon />
                  {"Rename team"}
                </span>
                <span
                  data-testid="Leave"
                  className={style.item}
                  onClick={() => {
                    setShowLeaveCollaborationModal(true);
                    setShowMenu(false);
                    setShowTeamNameTooltip(false);
                  }}
                >
                  <LeaveIcon />
                  {"Leave team"}
                </span>
                <span
                  data-testid="Delete"
                  className={classNames(style.item, style.delete)}
                  onClick={() => {
                    setShowDeleteCollaborationModal(true);
                    setShowMenu(false);
                    setShowTeamNameTooltip(false);
                  }}
                >
                  <TrashIcon />
                  {"Delete team"}
                </span>
              </>
            ) : (
              <>
                <span
                  data-testid="Manage team"
                  className={style.item}
                  onClick={() => {
                    setShowManageTeamModal(true);
                    setShowMenu(false);
                    setShowTeamNameTooltip(false);
                  }}
                >
                  <MembersIcon />
                  {"Team members"}
                </span>
                {team.permission === Permission.editor && (
                  <span
                    data-testid="create canvas"
                    className={style.item}
                    onClick={() => {
                      createNewBoard({
                        template: BlankCanvasTemplate,
                        inputProjectId: null,
                        inputTeamId: team.id,
                        source: "team-context-menu",
                      });
                      setShowMenu(false);
                      setShowTeamNameTooltip(false);
                    }}
                  >
                    <CreateCanvasIcon />
                    {"Create canvas"}
                  </span>
                )}
                <span
                  data-testid="Leave"
                  className={style.item}
                  onClick={() => {
                    setShowLeaveCollaborationModal(true);
                    setShowMenu(false);
                    setShowTeamNameTooltip(false);
                  }}
                >
                  <LeaveIcon />
                  {"Leave team"}
                </span>
              </>
            )}
          </div>
        </Floater>
      </>
    );
  };

  const renderVisibleText = () => (
    <InlineRename
      value={teamName}
      textStyle={InlineRenameStyle.regular}
      style={{
        minWidth: "130px",
        maxWidth: "130px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
      focus={inlineRenameFocus}
      onEditing={(editing: boolean) => setInlineRenameFocus(editing)}
      onChange={(newName: string) => {
        setTeamName(newName);
        fullTeam && handleEditTeamName({ ...fullTeam, name: newName });
      }}
      styles={styles}
    />
  );

  function renderTeamMembersModal() {
    if (showManageTeamModal) {
      return fullTeam?.accountTeam ? (
        <Modal dimBackground={true}>
          <AccountMembers
            title={fullTeam.name}
            onDismiss={() => {
              setShowManageTeamModal(false);
            }}
            onEditSeats={onEditSeats}
          />
        </Modal>
      ) : (
        <Modal dimBackground={true}>
          <ManageCollaborationMembers
            onDismiss={() => {
              setShowManageTeamModal(false);
            }}
            instanceId={team.id}
            type={InstanceType.Team}
            onEditSeats={onEditSeats}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  return (
    <div
      key={team.id}
      data-testid={teamName}
      ref={teamNameRef}
      className={classNames(style.cell, { [style.rename]: inlineRenameFocus })}
    >
      {hoverTeamName && teamName.length > 14 && showTeamNameTooltip && (
        <Tooltip
          side="right"
          label={teamName}
          relativeTo={teamNameRef}
          customStyle={{
            backgroundColor: "#DEE1EB",
            color: " #113357",
            fontSize: 12,
            fontWeight: 400,
            borderRadius: 4,
          }}
        />
      )}
      <div onClick={onClick} className={style.teamNameContainer}>
        {renderVisibleText()}
      </div>
      <div className={style.menuContainer}>
        {hoverCreateProject && (
          <Tooltip
            side="top"
            label={"Create new project"}
            relativeTo={createProjectRef}
            customStyle={{
              backgroundColor: "#113357",
              borderRadius: 6,
              color: " #FFF",
              fontSize: 12,
              fontWeight: 300,
            }}
          />
        )}
        <div
          onClick={() => {
            tracking.trackAnalyticsEvent("create-project-clicked", {
              category: consts.TRACKING_CATEGORY.PROJECT_ACTION,
              isNewHomePage: true,
            });
            handleNewProjectClick(team.id);
          }}
          className={classNames(style.newProjectIcon, {
            [style.selectedIcon]: isSelected,
            [style.hidden]: !(hoverTeamName || showMenu),
          })}
          ref={createProjectRef}
        >
          <NewTeamOrProjectIcon />
        </div>
        {hoverMenu && (
          <Tooltip
            side="top"
            label={"Settings"}
            relativeTo={menuButtonRef}
            customStyle={{
              backgroundColor: "#113357",
              borderRadius: 6,
              color: " #FFF",
              fontSize: 12,
              fontWeight: 300,
            }}
          />
        )}
        <div
          data-testid="teamcellmenuicon"
          onClick={(e) => menuClicked(e)}
          ref={menuButtonRef}
          className={classNames(style.menuIcon, {
            [style.selectedIcon]: isSelected,
            [style.hidden]: !(hoverTeamName || showMenu),
          })}
          onMouseEnter={() => setShowTeamNameTooltip(false)}
          onMouseLeave={() => setShowTeamNameTooltip(true)}
        >
          <MenuIcon />
        </div>
      </div>
      {showMenu && renderTeamMenu()}
      {showInviteTeammatesModal && fullTeam && (
        <Modal dimBackground={true}>
          <InviteModalNew
            instance={fullTeam}
            onDismiss={() => setShowInviteTeammatesModal(false)}
            inviteMode={InviteMode.Edit}
            onEditSeats={onEditSeats}
            source="edit-team-members"
            type={InstanceType.Team}
          />
        </Modal>
      )}
      {renderTeamMembersModal()}
      {showLeaveCollaborationModal && fullTeam && (
        <Modal dimBackground={true}>
          <LeaveCollaborationModalNew
            type={CollaborationType.Team}
            onDismiss={() => {
              setShowLeaveCollaborationModal(false);
            }}
            subtitle={`You will lose access to "${team.name}" team and it’s canvases.`}
            team={fullTeam}
          />
        </Modal>
      )}
      {showDeleteCollaborationModal && fullTeam && (
        <Modal dimBackground={true}>
          <DeleteCollaborationModalNew
            type={CollaborationType.Team}
            onDismiss={() => {
              setShowDeleteCollaborationModal(false);
            }}
            subtitle={`All projects and canvases within this team will be permanently deleted. You can move canvases to another team or export them before deleting.`}
            team={fullTeam}
            isSelected={isSelected}
            onDeleteSelectedCollaboration={onDeleteSelectedTeam}
          />
        </Modal>
      )}
    </div>
  );
}
