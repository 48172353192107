import React from "react";

export const AllTemplatesIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="157" height="117" fill="none" viewBox="0 0 157 117">
      <g filter="url(#filter0_d_20738_2683)">
        <g filter="url(#filter1_d_20738_2683)">
          <path
            fill="#F8FAFD"
            stroke="#D1D1D1"
            strokeWidth="0.494"
            d="M45.079 16.961c0-1.499 1.215-2.714 2.714-2.714h90.078a2.714 2.714 0 0 1 2.714 2.714V68.04c0 1.499-1.215 2.714-2.714 2.714H47.793a2.715 2.715 0 0 1-2.714-2.714z"
          ></path>
          <g filter="url(#filter2_d_20738_2683)">
            <path stroke="#848199" strokeWidth="0.4" d="M98.695 33.678h8.622M115.363 22.846h8.622"></path>
            <path stroke="#848199" strokeDasharray="0.42 0.83" strokeWidth="0.4" d="M100.363 44.096h8.622"></path>
            <path
              stroke="#848199"
              strokeWidth="0.4"
              d="M84.947 33.678h8.622M71.197 33.678h8.622M69.113 24.096v8.622M111.613 24.096v8.622M125.777 24.096v8.622M69.113 43.967v-8.622"
            ></path>
            <path
              stroke="#848199"
              strokeDasharray="0.42 0.83"
              strokeWidth="0.4"
              d="M111.613 43.967v-8.622M97.445 43.967v-8.622"
            ></path>
            <path stroke="#848199" strokeWidth="0.4" d="M57.447 33.678h8.622"></path>
            <rect width="11.761" height="5.235" x="105.777" y="41.592" fill="#E9DAFE" rx="0.967"></rect>
            <rect
              width="11.356"
              height="4.83"
              x="105.98"
              y="41.794"
              stroke="#AB87E2"
              strokeOpacity="0.9"
              strokeWidth="0.405"
              rx="0.765"
            ></rect>
            <rect
              width="11.356"
              height="4.83"
              x="63.484"
              y="41.794"
              fill="#9CDBC5"
              stroke="#00B875"
              strokeWidth="0.405"
              rx="0.765"
            ></rect>
            <rect width="11.761" height="5.235" x="105.777" y="30.852" fill="#E9DAFE" rx="0.967"></rect>
            <rect
              width="11.356"
              height="4.83"
              x="105.98"
              y="31.054"
              stroke="#AB87E2"
              strokeOpacity="0.9"
              strokeWidth="0.405"
              rx="0.765"
            ></rect>
            <rect width="5.833" height="0.417" x="108.695" y="33.262" fill="#B49ED5" rx="0.208"></rect>
            <rect width="5.833" height="0.417" x="108.695" y="44.094" fill="#B49ED5" rx="0.208"></rect>
            <rect
              width="11.356"
              height="4.83"
              x="63.484"
              y="31.054"
              fill="#9CDBC5"
              stroke="#00B875"
              strokeWidth="0.405"
              rx="0.765"
            ></rect>
            <rect
              width="11.356"
              height="4.83"
              x="78.068"
              y="30.966"
              fill="#9CDBC5"
              stroke="#00B875"
              strokeWidth="0.405"
              rx="0.765"
            ></rect>
            <rect
              width="7.928"
              height="7.928"
              x="91.901"
              y="33.736"
              fill="#FFE7BE"
              stroke="#F5B53D"
              strokeWidth="0.405"
              rx="0.765"
              transform="rotate(-45 91.901 33.736)"
            ></rect>
            <rect width="11.761" height="5.235" x="105.777" y="19.93" fill="#E9DAFE" rx="0.967"></rect>
            <rect
              width="11.356"
              height="4.83"
              x="105.98"
              y="20.132"
              stroke="#AB87E2"
              strokeOpacity="0.9"
              strokeWidth="0.405"
              rx="0.765"
            ></rect>
            <rect width="5.833" height="0.417" x="108.695" y="22.43" fill="#B49ED5" rx="0.208"></rect>
            <rect
              width="11.356"
              height="4.83"
              x="63.484"
              y="20.132"
              fill="#FFE7BE"
              stroke="#FFB200"
              strokeWidth="0.405"
              rx="0.765"
            ></rect>
            <rect width="5.833" height="0.417" x="66.197" y="33.262" fill="#56A98A" rx="0.208"></rect>
            <rect width="5.833" height="0.417" x="80.779" y="33.262" fill="#56A98A" rx="0.208"></rect>
            <rect width="5" height="0.417" x="94.947" y="33.68" fill="#DDB263" rx="0.208"></rect>
            <rect width="5.833" height="0.417" x="66.197" y="44.096" fill="#56A98A" rx="0.208"></rect>
            <rect width="5.833" height="0.417" x="66.197" y="22.43" fill="#FFB200" rx="0.208"></rect>
            <rect width="11.761" height="5.235" x="119.852" y="19.93" fill="#E9DAFE" rx="0.967"></rect>
            <rect
              width="11.356"
              height="4.83"
              x="120.054"
              y="20.132"
              stroke="#AB87E2"
              strokeOpacity="0.9"
              strokeWidth="0.405"
              rx="0.765"
            ></rect>
            <rect width="5.833" height="0.417" x="122.861" y="22.43" fill="#B49ED5" rx="0.208"></rect>
            <rect width="11.761" height="5.235" x="91.615" y="41.592" fill="#E9DAFE" rx="0.967"></rect>
            <rect
              width="11.356"
              height="4.83"
              x="91.818"
              y="41.794"
              stroke="#AB87E2"
              strokeOpacity="0.9"
              strokeWidth="0.405"
              rx="0.765"
            ></rect>
            <rect width="5.833" height="0.417" x="94.531" y="44.092" fill="#B49ED5" rx="0.208"></rect>
          </g>
          <circle cx="56.194" cy="33.679" r="4.283" fill="#113357" stroke="#113357" strokeWidth="0.601"></circle>
          <circle cx="125.775" cy="33.679" r="4.283" fill="#113357" stroke="#113357" strokeWidth="0.601"></circle>
        </g>
        <path
          fill="#F8FAFD"
          stroke="#D1D1D1"
          strokeWidth="0.494"
          d="M31.079 29.961c0-1.499 1.215-2.714 2.714-2.714h90.078a2.714 2.714 0 0 1 2.714 2.714V81.04c0 1.499-1.215 2.714-2.714 2.714H33.793a2.715 2.715 0 0 1-2.714-2.714z"
        ></path>
        <g filter="url(#filter3_d_20738_2683)">
          <path fill="#BEDCFF" stroke="#AFB3BF" strokeWidth="0.5" d="M39.914 38.449h15.768v10.407H39.914z"></path>
          <rect width="8.148" height="0.734" x="43.455" y="43.674" fill="#7DB9FF" rx="0.312"></rect>
          <path
            fill="#FFD9CD"
            stroke="#AFB3BF"
            strokeWidth="0.5"
            d="M39.914 34.633c0-.818.663-1.48 1.48-1.48h14.288v5.7H39.914z"
          ></path>
          <path fill="#BEDCFF" stroke="#AFB3BF" strokeWidth="0.5" d="M39.914 48.713h15.768V59.12H39.914z"></path>
          <rect width="8.148" height="0.734" x="43.455" y="53.953" fill="#7DB9FF" rx="0.312"></rect>
          <path fill="#BEDCFF" stroke="#AFB3BF" strokeWidth="0.5" d="M39.914 58.98h15.768v10.407H39.914z"></path>
          <rect width="8.148" height="0.734" x="43.455" y="64.238" fill="#7DB9FF" rx="0.312"></rect>
          <path
            fill="#BEDCFF"
            stroke="#AFB3BF"
            strokeWidth="0.5"
            d="M39.914 69.246h15.768v10.407H40.818a.904.904 0 0 1-.904-.903z"
          ></path>
          <rect width="8.148" height="0.734" x="43.455" y="74.027" fill="#7DB9FF" rx="0.312"></rect>
          <path fill="#fff" stroke="#AFB3BF" strokeWidth="0.5" d="M55.602 38.449H71.37v10.407H55.602z"></path>
          <path fill="#FFD9CD" stroke="#AFB3BF" strokeWidth="0.5" d="M55.602 33.152H71.37v5.701H55.602z"></path>
          <path fill="#fff" stroke="#AFB3BF" strokeWidth="0.5" d="M86.85 38.449h15.768v10.407H86.85z"></path>
          <path fill="#FFD9CD" stroke="#AFB3BF" strokeWidth="0.5" d="M86.85 33.152h15.768v5.701H86.85z"></path>
          <path fill="#fff" stroke="#AFB3BF" strokeWidth="0.5" d="M71.393 38.449h15.768v10.407H71.393z"></path>
          <path fill="#FFD9CD" stroke="#AFB3BF" strokeWidth="0.5" d="M71.393 33.152h15.768v5.701H71.393z"></path>
          <path fill="#fff" stroke="#AFB3BF" strokeWidth="0.5" d="M102.646 38.449h15.768v10.407h-15.768z"></path>
          <path
            fill="#FFD9CD"
            stroke="#AFB3BF"
            strokeWidth="0.5"
            d="M102.646 33.152h14.287a1.48 1.48 0 0 1 1.481 1.481v4.22h-15.768z"
          ></path>
          <path
            fill="#fff"
            stroke="#AFB3BF"
            strokeWidth="0.5"
            d="M55.602 48.713H71.37V59.12H55.602zM86.85 48.713h15.768V59.12H86.85z"
          ></path>
          <path
            fill="#fff"
            stroke="#AFB3BF"
            strokeWidth="0.5"
            d="M71.393 48.713h15.768V59.12H71.393zM102.646 48.713h15.768V59.12h-15.768zM55.602 58.98H71.37v10.407H55.602z"
          ></path>
          <path fill="#fff" stroke="#AFB3BF" strokeWidth="0.5" d="M86.85 58.98h15.768v10.407H86.85z"></path>
          <path
            fill="#fff"
            stroke="#AFB3BF"
            strokeWidth="0.5"
            d="M71.393 58.98h15.768v10.407H71.393zM102.646 58.98h15.768v10.407h-15.768zM55.602 69.246H71.37v10.407H55.602z"
          ></path>
          <path fill="#fff" stroke="#AFB3BF" strokeWidth="0.5" d="M86.85 69.246h15.768v10.407H86.85z"></path>
          <path
            fill="#fff"
            stroke="#AFB3BF"
            strokeWidth="0.5"
            d="M71.393 69.246h15.768v10.407H71.393zM102.646 69.246h15.768v8.927a1.48 1.48 0 0 1-1.481 1.48h-14.287z"
          ></path>
        </g>
        <g filter="url(#filter4_d_20738_2683)">
          <path
            fill="#F8FAFD"
            stroke="#D1D1D1"
            strokeWidth="0.494"
            d="M16.247 44.06c0-1.5 1.215-2.716 2.714-2.716h90.078a2.714 2.714 0 0 1 2.714 2.715v51.077c0 1.5-1.215 2.715-2.714 2.715H18.961a2.715 2.715 0 0 1-2.714-2.715z"
          ></path>
          <g filter="url(#filter5_d_20738_2683)">
            <rect
              width="23.252"
              height="15.591"
              x="52.555"
              y="49.897"
              fill="#D6BAFF"
              fillOpacity="0.9"
              rx="1.222"
            ></rect>
            <rect
              width="22.74"
              height="15.08"
              x="52.81"
              y="50.152"
              stroke="#AB87E2"
              strokeOpacity="0.9"
              strokeWidth="0.511"
              rx="0.966"
            ></rect>
            <path
              fill="#FCFAFF"
              fillOpacity="0.9"
              stroke="#AB87E2"
              strokeWidth="0.511"
              d="M52.81 51.119c0-.534.433-.967.967-.967h20.807c.534 0 .967.433.967.967v9.349H52.81z"
            ></path>
            <path
              fill="#EDEDED"
              stroke="#B290E5"
              strokeWidth="0.511"
              d="M68.092 49.896c0 2.008-1.65 3.642-3.692 3.642s-3.693-1.634-3.693-3.642 1.65-3.642 3.693-3.642c2.042 0 3.692 1.633 3.692 3.642Z"
            ></path>
            <rect width="11.845" height="0.866" x="58.258" y="55.959" fill="#B49ED5" rx="0.433"></rect>
            <path
              stroke="#848199"
              strokeWidth="0.511"
              d="M64.423 65.852v1.738c0 1.13-.917 2.046-2.047 2.046H48.81c-.995 0-1.802.806-1.802 1.802"
            ></path>
            <path
              stroke="#848199"
              strokeWidth="0.511"
              d="M64.422 65.852v1.738c0 1.13.918 2.046 2.047 2.046h13.567c.995 0 1.802.806 1.802 1.802"
            ></path>
            <rect
              width="22.74"
              height="15.08"
              x="35.76"
              y="75.765"
              fill="#A0C9F9"
              stroke="#0091E5"
              strokeWidth="0.511"
              rx="0.966"
            ></rect>
            <path
              fill="#FCFAFF"
              fillOpacity="0.9"
              stroke="#0091E5"
              strokeWidth="0.511"
              d="M35.76 76.732c0-.534.432-.967.966-.967h20.807c.534 0 .967.433.967.967v9.35H35.76z"
            ></path>
            <rect width="11.845" height="0.866" x="41.209" y="81.572" fill="#0091E5" rx="0.433"></rect>
            <path
              fill="#EDEDED"
              stroke="#0091E5"
              strokeWidth="0.511"
              d="M51.044 75.51c0 2.007-1.65 3.641-3.693 3.641s-3.693-1.633-3.693-3.642 1.65-3.642 3.693-3.642c2.042 0 3.693 1.634 3.693 3.642Z"
            ></path>
            <mask
              id="mask0_20738_2683"
              width="8"
              height="8"
              x="43"
              y="71"
              maskUnits="userSpaceOnUse"
              style={{ maskType: "alpha" }}
            >
              <path
                fill="#EDEDED"
                stroke="#B290E5"
                strokeWidth="0.511"
                d="M50.74 74.932c0 1.98-1.533 3.567-3.401 3.567s-3.402-1.586-3.402-3.567c0-1.98 1.534-3.567 3.402-3.567s3.401 1.586 3.401 3.567Z"
              ></path>
            </mask>
            <g fill="#fff" stroke="#657E9A" strokeWidth="0.293" mask="url(#mask0_20738_2683)">
              <path d="M45.117 77.91c0-.89.72-1.61 1.609-1.61h.935c.889 0 1.61.72 1.61 1.61v1.549h-4.154zM48.437 74.562c0 .646-.55 1.18-1.243 1.18s-1.243-.534-1.243-1.18.55-1.181 1.243-1.181 1.243.535 1.243 1.18Z"></path>
            </g>
          </g>
          <mask
            id="mask1_20738_2683"
            width="9"
            height="9"
            x="60"
            y="45"
            maskUnits="userSpaceOnUse"
            style={{ maskType: "alpha" }}
          >
            <path
              fill="#EDEDED"
              stroke="#B290E5"
              strokeWidth="0.511"
              d="M67.797 49.395c0 1.98-1.534 3.567-3.402 3.567s-3.401-1.586-3.401-3.567c0-1.98 1.533-3.567 3.401-3.567s3.402 1.586 3.402 3.567Z"
            ></path>
          </mask>
          <g fill="#fff" stroke="#657E9A" strokeWidth="0.293" mask="url(#mask1_20738_2683)">
            <path d="M62.178 52.37c0-.888.72-1.608 1.608-1.608h.936c.889 0 1.609.72 1.609 1.608v1.55h-4.154zM65.496 49.023c0 .646-.55 1.18-1.243 1.18s-1.243-.534-1.243-1.18.55-1.181 1.243-1.181c.692 0 1.243.535 1.243 1.18Z"></path>
          </g>
          <rect width="23.252" height="15.591" x="70.287" y="75.51" fill="#9CDBC5" rx="1.222"></rect>
          <rect
            width="22.74"
            height="15.08"
            x="70.543"
            y="75.765"
            stroke="#00B875"
            strokeOpacity="0.9"
            strokeWidth="0.511"
            rx="0.966"
          ></rect>
          <path
            fill="#FCFAFF"
            fillOpacity="0.9"
            stroke="#00B875"
            strokeWidth="0.511"
            d="M70.543 76.732c0-.534.432-.967.966-.967h20.808c.533 0 .966.433.966.967v9.35h-22.74z"
          ></path>
          <path
            fill="#EDEDED"
            stroke="#00B875"
            strokeWidth="0.511"
            d="M85.825 75.51c0 2.007-1.65 3.641-3.693 3.641s-3.693-1.633-3.693-3.642 1.65-3.642 3.693-3.642c2.042 0 3.693 1.634 3.693 3.642Z"
          ></path>
          <rect width="11.845" height="0.866" x="75.99" y="81.572" fill="#00B875" rx="0.433"></rect>
          <mask
            id="mask2_20738_2683"
            width="8"
            height="8"
            x="78"
            y="71"
            maskUnits="userSpaceOnUse"
            style={{ maskType: "alpha" }}
          >
            <path
              fill="#EDEDED"
              stroke="#B290E5"
              strokeWidth="0.511"
              d="M85.559 74.932c0 1.98-1.534 3.567-3.402 3.567s-3.401-1.586-3.401-3.567c0-1.98 1.533-3.567 3.401-3.567s3.402 1.586 3.402 3.567Z"
            ></path>
          </mask>
          <g fill="#fff" stroke="#657E9A" strokeWidth="0.293" mask="url(#mask2_20738_2683)">
            <path d="M79.94 77.91c0-.89.72-1.61 1.608-1.61h.936c.888 0 1.609.72 1.609 1.61v1.549h-4.154zM83.257 74.562c0 .646-.55 1.18-1.243 1.18s-1.243-.534-1.243-1.18.55-1.181 1.243-1.181 1.243.535 1.243 1.18Z"></path>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_20738_2683"
          width="156.832"
          height="116.098"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2"></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_20738_2683"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_20738_2683" result="shape"></feBlend>
        </filter>
        <filter
          id="filter1_d_20738_2683"
          width="104"
          height="65"
          x="40.832"
          y="10"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_20738_2683"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_20738_2683" result="shape"></feBlend>
        </filter>
        <filter
          id="filter2_d_20738_2683"
          width="81.427"
          height="34.16"
          x="53.816"
          y="18.114"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1.816"></feOffset>
          <feGaussianBlur stdDeviation="1.816"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_20738_2683"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_20738_2683" result="shape"></feBlend>
        </filter>
        <filter
          id="filter3_d_20738_2683"
          width="86.58"
          height="54.582"
          x="35.874"
          y="31.007"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1.895"></feOffset>
          <feGaussianBlur stdDeviation="1.895"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_20738_2683"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_20738_2683" result="shape"></feBlend>
        </filter>
        <filter
          id="filter4_d_20738_2683"
          width="104"
          height="65"
          x="12"
          y="37.098"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_20738_2683"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_20738_2683" result="shape"></feBlend>
        </filter>
        <filter
          id="filter5_d_20738_2683"
          width="55.768"
          height="54.282"
          x="30.915"
          y="43.703"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="2.295"></feOffset>
          <feGaussianBlur stdDeviation="2.295"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_20738_2683"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_20738_2683" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  );
};
