import React, { useRef, useState } from "react";
import collaborationStyle from "./collaboration-modal-new.module.css";
import style from "./new-collaboration-modal.module.css";
import StyledButton, { ButtonSize, ButtonStyle } from "frontend/ui-components/styled-button";
import CollaborationHeaderNew from "./collaboration-header-new";

export default function CreateCollaborationModal({
  type,
  subtitle,
  inputPlaceholder,
  onDismiss,
  onCreateTeam,
  onCreateProject,
}: {
  type: any;
  subtitle: string;
  inputPlaceholder: string;
  onDismiss: () => void;
  onCreateTeam?: (name: string) => void;
  onCreateProject?: (name: string) => void;
}) {
  const [name, setName] = useState<string>("");

  const modalRef = useRef(null);

  return (
    <div className={collaborationStyle.container} ref={modalRef}>
      <CollaborationHeaderNew title={`Create new ${type}`} subtitle={subtitle} onDismiss={onDismiss} />
      <div className={style.inviteField}>
        <form
          className={collaborationStyle.nameInput}
          onSubmit={(e) => {
            e.preventDefault();
            onCreateTeam && onCreateTeam(name);
            onCreateProject && onCreateProject(name);
            onDismiss();
          }}
        >
          <input
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder={inputPlaceholder}
            autoFocus={true}
            autoComplete="off"
          />
        </form>
        <StyledButton
          title={`Create ${type}`}
          style={ButtonStyle.filled}
          size={ButtonSize.regular}
          customStyle={{
            width: "130px",
            letterSpacing: "0.124px",
            alignSelf: "end",
            borderRadius: "6px",
            color: "#FFF",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
          onClick={() => {
            onCreateTeam && onCreateTeam(name);
            onCreateProject && onCreateProject(name);
            onDismiss();
          }}
        />
      </div>
    </div>
  );
}
