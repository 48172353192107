import { TeamWithProjects } from "shared/datamodel/schemas/team";
import style from "./initial-icon-new.module.css";
import consts from "shared/consts";

export function InitialIconNew({ text, list }: { text: string; list: TeamWithProjects[] }) {
  const index = list.findIndex((obj) => {
    return Object.values(obj).some((value) => value === text);
  });
  const initials = text[0].toUpperCase();
  return (
    <div
      className={style.initialsIcon}
      style={{ backgroundColor: consts.TEAM_PICKER_COLOR_PALETTE[index % consts.TEAM_PICKER_COLOR_PALETTE.length] }}
    >
      <span>{initials}</span>
    </div>
  );
}
