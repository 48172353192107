import React from "react";

export const MenuHorizontalIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="3" viewBox="0 0 12 3" fill="none">
      <circle cx="1.2" cy="1.2" r="1.2" fill="currentColor" />
      <circle cx="5.9998" cy="1.2" r="1.2" fill="currentColor" />
      <circle cx="10.7996" cy="1.2" r="1.2" fill="currentColor" />
    </svg>
  );
};
