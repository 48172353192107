import React, { useRef } from "react";
import SimpleBar from "simplebar-react";
import { CanvasTemplate, TipName } from "shared/datamodel/schemas";
import style from "./side-menu-new.module.css";
import { Project } from "shared/datamodel/schemas/project";
import { TeamWithProjects } from "shared/datamodel/schemas/team";
import { HomeIcon } from "frontend/icons/home";
import { SideMenuSection } from "./side-menu-section";
import { MyCanvasesIcon } from "frontend/icons/my-canvases";
import { TeamProjectHierarchy } from "./team-project-hierarchy";
import { SharedWithMeIcon } from "frontend/icons/shared-with-me";
import { TemplatesIcon } from "frontend/icons/templates";
import { BoardSectionType, BoardSection } from "shared/datamodel/schemas/board-section";
import WalkthroughTipPresenter from "frontend/walkthrough-tips/walkthrough-tip-presenter";
import { WalkthroughTipType } from "frontend/walkthrough-tips/walkthrough-tip-model";
import consts from "shared/consts";
import tracking from "frontend/tracking";

export default function SideMenuNew({
  selectedTeamId,
  setSelectedTeam,
  selectedProjectId,
  setSelectedProject,
  selectedSection,
  createNewBoard,
  onEditSeats,
  onMyCanvasesClick,
  onRecentCanvasesClick,
  onSharedWithMeClick,
  setShowRestrictedModal,
  handleNoPermissionToCreate,
  setShowTemplatesModal,
}: {
  selectedTeamId: number | null;
  setSelectedTeam: (team: TeamWithProjects) => void;
  selectedProjectId: number | null;
  setSelectedProject: (project: Project) => void;
  selectedSection: BoardSection;
  createNewBoard: ({
    template,
    inputProjectId,
    inputTeamId,
    source,
  }: {
    template: CanvasTemplate;
    inputProjectId: number | null;
    inputTeamId: number | null;
    source: string;
  }) => void;
  onEditSeats: () => void;
  onMyCanvasesClick: () => void;
  onRecentCanvasesClick: () => void;
  onSharedWithMeClick: () => void;
  setShowRestrictedModal: (show: boolean) => void;
  handleNoPermissionToCreate: () => void;
  setShowTemplatesModal: (show: boolean) => void;
}) {
  const sectionsRef = useRef<HTMLDivElement>(null);
  const teamsAndProjectsRef = useRef<HTMLDivElement>(null);

  return (
    <div className={style.container}>
      <div className={style.startContainer} ref={sectionsRef}>
        <SideMenuSection
          name={"Home"}
          icon={() => <HomeIcon />}
          onClick={onRecentCanvasesClick}
          isSelected={selectedSection.type === BoardSectionType.RECENT}
        />
        <SideMenuSection
          name={"My Canvases"}
          icon={() => <MyCanvasesIcon />}
          onClick={onMyCanvasesClick}
          isSelected={selectedSection.type === BoardSectionType.MY_BOARDS}
        />
        <SideMenuSection
          name={"Shared with me"}
          icon={() => <SharedWithMeIcon />}
          onClick={onSharedWithMeClick}
          isSelected={selectedSection.type === BoardSectionType.SHARED_WITH_ME}
        />
        <SideMenuSection
          name={"Templates"}
          icon={() => <TemplatesIcon />}
          onClick={() => {
            tracking.trackAnalyticsEvent("templates-library-clicked", {
              category: consts.TRACKING_CATEGORY.TEMPLATES,
              source: "side-menu",
              isNewHomePage: true,
            });
            setShowTemplatesModal(true);
          }}
          isSelected={selectedSection.type === BoardSectionType.TEMPLATES}
        />
      </div>
      <div className={style.divider}></div>
      <div className={style.centerContainer} ref={teamsAndProjectsRef}>
        <SimpleBar
          scrollbarMaxSize={100}
          style={{
            maxHeight: "90%",
            width: "100%",
          }}
        >
          <TeamProjectHierarchy
            selectedProjectId={selectedProjectId}
            selectedTeamId={selectedTeamId}
            setSelectedProject={setSelectedProject}
            onEditSeats={onEditSeats}
            setSelectedTeam={setSelectedTeam}
            setShowRestrictedModal={setShowRestrictedModal}
            handleNoPermissionToCreate={handleNoPermissionToCreate}
            createNewBoard={createNewBoard}
            resetSelectedInstance={onRecentCanvasesClick}
          />
        </SimpleBar>
      </div>
      <div className={style.endContainer}>
        <div className={style.divider}></div>
      </div>
      <WalkthroughTipPresenter
        tipName={TipName.walkthroughHomePageSections}
        attachToRef={sectionsRef}
        side="left"
        type={WalkthroughTipType.HomePage}
      />
      <WalkthroughTipPresenter
        tipName={TipName.walkthroughTeamsAndProjects}
        attachToRef={teamsAndProjectsRef}
        side="right"
        type={WalkthroughTipType.HomePage}
      />
    </div>
  );
}
