import React from "react";

export const ProjectIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
      <path
        d="M2.33333 1H5L7 3H11.6667C12.0203 3 12.3594 3.14048 12.6095 3.39052C12.8595 3.64057 13 3.97971 13 4.33333V9.66667C13 10.0203 12.8595 10.3594 12.6095 10.6095C12.3594 10.8595 12.0203 11 11.6667 11H2.33333C1.97971 11 1.64057 10.8595 1.39052 10.6095C1.14048 10.3594 1 10.0203 1 9.66667V2.33333C1 1.97971 1.14048 1.64057 1.39052 1.39052C1.64057 1.14048 1.97971 1 2.33333 1Z"
        stroke="#A0A2B1"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line x1="9" y1="3.10001" x2="1" y2="3.10001" stroke="#A0A2B1" />
    </svg>
  );
};
