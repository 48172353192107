import React, { useEffect, useRef, useState } from "react";
import style from "./add-canvases-to-project-modal-new.module.css";
import StyledButton from "frontend/ui-components/styled-button";
import { Board } from "shared/datamodel/schemas";
import CollaborationHeaderNew from "../collaboration-header-new";
import NoCanvasesEmptystate from "../../../boards-grid/no-canvases-empty-state";
import SearchEmptyState from "../empty-state/search-empty-state";
import { getPathPrefix } from "frontend/utils/getPathPrefix";

export default function AddCanvasesToProjectModelNew({
  onDismiss,
  onCreateProject,
  boards,
}: {
  onDismiss: () => void;
  onCreateProject?: (canvases: Board[], newCanvasForProject?: boolean) => void;
  boards: Board[];
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<Board[]>([]);

  const modalRef = useRef(null);

  const [checked, setChecked] = useState<Board[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);

  useEffect(() => {
    if (searchTerm) {
      const filteredBoards: Board[] =
        searchTerm && boards?.values()
          ? (Array.from(boards.values()).filter((board) => {
              const name = board.name.toLowerCase();
              return name?.includes(searchTerm);
            }) as Board[])
          : (Array.from(boards.values()) as Board[]) ?? [];
      filteredBoards && setSearchResults(filteredBoards);
    } else {
      setSearchResults(boards);
    }
  }, [searchTerm, boards]);

  // Add/Remove checked item from list
  const handleCheck = (boardId: number) => {
    const updatedList = [...checked];
    if (updatedList.map((b) => b.id).includes(boardId)) {
      updatedList.splice(checked.map((b) => b.id).indexOf(boardId), 1);
    } else {
      const newBoard = boards.find((board) => board.id === boardId);
      newBoard && updatedList.push(newBoard);
    }
    setChecked(updatedList);
  };

  const handleCheckAll = () => {
    setChecked(checkAll ? [] : boards);
    setCheckAll(!checkAll);
  };

  function renderCard(board: Board) {
    const backgroundImage = board.thumbnail ?? `/images/new-canvas-placeholder-${board.id % 4}.png`;
    return (
      <div key={board.id} data-testid={board.name} onClick={() => handleCheck(board.id)} className={style.card}>
        <div
          className={style.boardImage}
          onClick={() => handleCheck(board.id)}
          style={{
            background: `url(${getPathPrefix(backgroundImage)})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "100% 100%",
          }}
        >
          <input
            value={board.id}
            key={board.id}
            type="checkbox"
            onChange={() => handleCheck(board.id)}
            checked={checked.map((b) => b.id).includes(board.id)}
            autoComplete="off"
          />
        </div>
        <div className={style.titleContainer}>
          <div className={style.boardName}>{board.name}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={style.container} ref={modalRef}>
      <div style={{ padding: "25px 25px 0 25px" }}>
        <CollaborationHeaderNew
          title={"Add canvases to your project"}
          subtitle={boards.length > 0 ? "These canvases will be relocated to this project." : undefined}
          onDismiss={onDismiss}
        />
      </div>
      {boards.length > 0 ? (
        <>
          <div className={style.searchSection}>
            <div className={style.selectAll} onClick={handleCheckAll}>
              <input type="checkbox" onChange={handleCheckAll} checked={checkAll} />
              <span className={style.chooseAllText}>All</span>
            </div>
            <div className={style.searchContainer}>
              <input
                type="text"
                autoComplete="off"
                value={searchTerm}
                placeholder="Search canvases"
                autoFocus
                onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
              />
            </div>
          </div>
          <div className={style.divider}></div>
          <div className={style.boardsContainer}>
            {searchResults.length > 0 || searchTerm === "" ? (
              <div className={style.boards}>{searchResults.map(renderCard)}</div>
            ) : (
              <SearchEmptyState searchTerm={searchTerm} subtitle={"did not match any canvas name"} />
            )}
          </div>
        </>
      ) : (
        <NoCanvasesEmptystate
          subtitle={"No canvases yet!\n Create your first canvas for this project"}
          onDismiss={() => {
            onCreateProject && onCreateProject([], true);
            onDismiss();
          }}
        />
      )}
      <div className={style.divider}></div>
      <div className={style.footer}>
        <span
          onClick={() => {
            onCreateProject && onCreateProject([]);
            onDismiss();
          }}
          className={style.link}
        >
          Skip for now
        </span>
        <StyledButton
          title={"Add canvases"}
          onClick={() => {
            onCreateProject && onCreateProject(checked);
            onDismiss();
          }}
          customStyle={{
            width: "130px",
            letterSpacing: "0.124px",
            alignSelf: "end",
            borderRadius: "6px",
            color: "#FFF",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
          enabled={boards.length > 0 && checked.length > 0}
        />
      </div>
    </div>
  );
}
