import { useRef, useState } from "react";
import style from "../collaboration-invite-modal-new.module.css";
import permissionStyle from "../permission-menu-new.module.css";
import { Floater } from "frontend/ui-components/floaters/floater";
import classNames from "classnames";
import { useOnClickOutside } from "usehooks-ts";
import { Permission } from "shared/datamodel/schemas";

export default function BulkPermissionRound({
  usersPermission,
  setUsersPermission,
  showText = true,
}: {
  usersPermission: Permission;
  setUsersPermission: (userPermission: Permission) => void;
  showText?: boolean;
}) {
  const [showPermissionMenu, setShowPermissionMenu] = useState<boolean | null>(false);

  const permissionRef = useRef(null);
  const permissionMenuRef = useRef(null);
  const ref = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(permissionMenuRef, () => {
    setShowPermissionMenu(false);
  });

  const selectedIcon = (
    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2 5.5L5.66667 9L13 2" stroke="#1973FF" strokeWidth="1.5" strokeLinecap="square" />
    </svg>
  );

  const renderMembersPermissionDropDown = (value: Permission, setValue: (value: Permission) => void) => {
    return (
      <>
        <div
          data-testid="permissiondropdown"
          className={style.permissionDropDown}
          ref={permissionRef}
          onClick={() => {
            setShowPermissionMenu(true);
          }}
        >
          <span>{value === Permission.viewer ? "Viewer" : "Editor"}</span>
          {showPermissionMenu ? (
            <span>
              <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.0013 4.55762L4.55859 1.00033" stroke="#11355E" strokeLinecap="square" />
                <path d="M8.11523 4.55762L4.55794 1.00033" stroke="#11355E" strokeLinecap="square" />
              </svg>
            </span>
          ) : (
            <span>
              <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.11442 1L4.55713 4.55729" stroke="#11355E" strokeLinecap="square" />
                <path d="M1 1L4.55729 4.55729" stroke="#11355E" strokeLinecap="square" />
              </svg>
            </span>
          )}
        </div>
        {showPermissionMenu && (
          <Floater
            relativeTo={permissionRef}
            side={"bottom"}
            extraStyle={{
              zIndex: "2",
              borderTop: "none",
              marginTop: "-15px",
            }}
            ref={permissionMenuRef}
          >
            <div className={permissionStyle.bulkPermissionMenu}>
              <div
                data-testid="Viewer"
                className={classNames(permissionStyle.item, {
                  [permissionStyle.selectedPermission]: value === Permission.viewer,
                })}
                onClick={() => {
                  setValue(Permission.viewer);
                  setShowPermissionMenu(false);
                }}
              >
                <span
                  className={classNames(permissionStyle.name, { [permissionStyle.bold]: value === Permission.viewer })}
                >
                  Viewer
                </span>
                {value === Permission.viewer && selectedIcon}
              </div>
              <div
                data-testid="Editor"
                className={classNames(permissionStyle.item, {
                  [permissionStyle.selectedPermission]: value === Permission.editor,
                })}
                onClick={() => {
                  setValue(Permission.editor);
                  setShowPermissionMenu(false);
                }}
              >
                <span
                  className={classNames(permissionStyle.name, { [permissionStyle.bold]: value === Permission.editor })}
                >
                  Editor
                </span>
                {value === Permission.editor && selectedIcon}
              </div>
            </div>
          </Floater>
        )}
      </>
    );
  };
  return (
    <div className={style.permissionContainer} ref={ref}>
      {showText && (
        <span className={permissionStyle.modalTextLine}>Teammates will get default access to all canvases as </span>
      )}
      {renderMembersPermissionDropDown(usersPermission, setUsersPermission)}
    </div>
  );
}
