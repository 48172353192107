import React from "react";

export const MoveIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
      <path
        d="M1 5H1.5M4.5 5H6M9 5H15"
        stroke="#113357"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 9L16 5" stroke="#113357" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12 1L16 5" stroke="#113357" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
