import React from "react";

export const MeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.3 1.21225C4.14696 1.21225 3.21225 2.14696 3.21225 3.3C3.21225 4.45304 4.14696 5.38776 5.3 5.38776C6.45304 5.38776 7.38776 4.45304 7.38776 3.3C7.38776 2.14696 6.45304 1.21225 5.3 1.21225ZM2 3.3C2 1.47746 3.47746 0 5.3 0C7.12254 0 8.6 1.47746 8.6 3.3C8.6 5.12254 7.12254 6.6 5.3 6.6C3.47746 6.6 2 5.12254 2 3.3Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.86874 9.27512C8.38348 8.93958 7.79455 8.75843 7.18953 8.75863H3.80897C3.20473 8.75846 2.61636 8.93917 2.13145 9.27395C1.64655 9.60872 1.29077 10.0797 1.1169 10.6171C1.02624 10.8973 0.708162 11.0561 0.406443 10.972C0.104725 10.8878 -0.0663771 10.5924 0.0242765 10.3122C0.268741 9.5567 0.768971 8.89446 1.45076 8.42376C2.1325 7.9531 2.95946 7.69902 3.80897 7.69922C3.80903 7.69922 3.80892 7.69922 3.80897 7.69922L7.18914 7.69922C7.18921 7.69922 7.18907 7.69922 7.18914 7.69922C8.03974 7.69899 8.8679 7.95368 9.55012 8.42542C10.2324 8.89721 10.7325 9.56088 10.976 10.3177C11.0662 10.5981 10.8946 10.8932 10.5927 10.9769C10.2908 11.0607 9.97303 10.9013 9.88285 10.621C9.70966 10.0827 9.35399 9.61067 8.86874 9.27512Z"
        fill="white"
      />
    </svg>
  );
};
