import React from "react";
import cssstyle from "./board-card-wrapper-new.module.css";

export function BoardCardWrapperNew({ children, style = null }: { children: any; style?: any }) {
  return (
    <div className={cssstyle.container} style={style}>
      {children}
    </div>
  );
}
