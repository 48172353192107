import { useEffect, useState } from "react";
import Modal from "frontend/modal/modal";
import CreateCollaborationModal from "frontend/modals/collaboration/create-collaboration-modal";
import { Project } from "shared/datamodel/schemas/project";
import AddCanvasesToProjectModelNew from "frontend/modals/collaboration/project/add-canvases-to-project-modal-new";
import { CollaborationType, InviteMode } from "state-atoms/teams-atoms";
import { useProject } from "frontend/hooks/use-project";
import { Board, CanvasTemplate } from "shared/datamodel/schemas";
import useStateValue from "frontend/state/value";
import RestrictedUserModal from "frontend/modals/restricted-user-modal";
import InviteModalNew from "frontend/modals/invite-modal-new";
import { InstanceType } from "shared/datamodel/schemas/ability";
import AppLoader from "frontend/loader/app-loader";
import { useProjectsCache } from "frontend/hooks/caching/use-projects-cache";

export function CreateProject({
  parentTeamId,
  createNewBoard,
  onEditSeats,
  setSelectedProject,
}: {
  parentTeamId: number;
  createNewBoard: ({
    template,
    inputProjectId,
    inputTeamId,
    source,
  }: {
    template: CanvasTemplate;
    inputProjectId: number | null;
    inputTeamId: number | null;
    source: string;
  }) => void;
  onEditSeats: () => void;
  setSelectedProject: (project: Project) => void;
}) {
  const [projectName, setProjectName] = useState("");
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(true);
  const [showInviteProjectMembersModal, setShowInviteProjectMembersModal] = useState(false);
  const [showAddCanvasesToProjectModel, setShowAddCanvasesToProjectModel] = useState(false);
  const [showRestrictedModal, setShowRestrictedModal] = useState<boolean>(false);
  const [canvases, setCanvases] = useState<Board[]>([]);
  const [newCanvasForProject, setNewCanvasForProject] = useState<boolean>(false);
  const [createdProject, setCreatedProject] = useState<Project | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [{ boards }] = useStateValue();

  const boardsForProjectCreation = [...boards.values()].filter(
    (board) => !board.projectId && (board.teamId === parentTeamId || board.teamId === null) && board.isOwner
  );

  const { addCanvasesToProjectNew } = useProject();
  const { createProject: handleCreateProject } = useProjectsCache();

  useEffect(() => {
    createdProject && addCanvasesToProjectNew(createdProject?.id, canvases, createNewBoard, newCanvasForProject);
  }, [createdProject, canvases, newCanvasForProject]);

  async function createProject(parentTeamId: number, name: string) {
    setIsLoading(true);
    const project = await handleCreateProject({ name, teamId: parentTeamId });
    setIsLoading(false);
    if (project) {
      setCreatedProject(project);
      setShowInviteProjectMembersModal(true);
      setProjectName(name);
      setSelectedProject(project);
    } else {
      setShowRestrictedModal(true);
    }
  }

  function onCloseCreateProjectModal() {
    setShowCreateProjectModal(false);
  }

  function onCloseAddCanvasesToProjectModal() {
    setShowAddCanvasesToProjectModel(false);
  }

  return (
    <>
      {showCreateProjectModal && (
        <Modal dimBackground={true} onDismissOnEscape={onCloseCreateProjectModal}>
          <CreateCollaborationModal
            onDismiss={onCloseCreateProjectModal}
            onCreateProject={(name: string) => {
              setProjectName(name);
              onCloseCreateProjectModal();
              if (boardsForProjectCreation.length > 0) {
                setShowAddCanvasesToProjectModel(true);
              }
            }}
            type={CollaborationType.Project}
            subtitle={"People in your project have access to every canvas."}
            inputPlaceholder={"Name your new project"}
          />
        </Modal>
      )}
      {showAddCanvasesToProjectModel && (
        <Modal dimBackground={true} onDismissOnEscape={onCloseAddCanvasesToProjectModal}>
          <AddCanvasesToProjectModelNew
            onDismiss={onCloseAddCanvasesToProjectModal}
            onCreateProject={(canvases: Board[], newCanvasForProject?: boolean) => {
              setCanvases(canvases);
              setNewCanvasForProject(newCanvasForProject ?? false);
              setShowAddCanvasesToProjectModel(false);
              setShowInviteProjectMembersModal(true);
              createProject(parentTeamId, projectName);
            }}
            boards={boardsForProjectCreation}
          />
        </Modal>
      )}
      {showInviteProjectMembersModal && createdProject && (
        <Modal dimBackground={true}>
          <InviteModalNew
            name={projectName}
            onDismiss={() => {
              setShowInviteProjectMembersModal(false);
            }}
            inviteMode={InviteMode.Create}
            onEditSeats={onEditSeats}
            source="create-project"
            instance={createdProject}
            type={InstanceType.Project}
          />
        </Modal>
      )}
      {showRestrictedModal && (
        <RestrictedUserModal trackingEvent={"home"} onDismiss={() => setShowRestrictedModal(false)} />
      )}
      {isLoading && <AppLoader />}
    </>
  );
}
