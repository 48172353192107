import React, { useRef } from "react";
import collaborationStyle from "./collaboration-modal-new.module.css";
import style from "./new-collaboration-modal.module.css";
import manageStyle from "./manage-collaboration-modal-new.module.css";
import StyledButton, { ButtonSize, ButtonStyle } from "frontend/ui-components/styled-button";
import { Project } from "shared/datamodel/schemas/project";
import CollaborationHeaderNew from "./collaboration-header-new";
import useOutsideRef from "frontend/utils/click-outside-handler";
import { State, Team } from "shared/datamodel/schemas/team";
import { CollaborationType } from "state-atoms";
import { useTeamsCache } from "frontend/hooks/caching/use-teams-cache";
import { useProjectsCache } from "frontend/hooks/caching/use-projects-cache";
import useStateValue from "frontend/state/value";

export default function LeaveCollaborationModalNew({
  type,
  subtitle,
  team,
  project,
  onDismiss,
}: {
  type: CollaborationType;
  subtitle: string;
  team?: Team;
  project?: Project;
  onDismiss: () => void;
}) {
  const modalRef = useRef(null);

  useOutsideRef(modalRef, () => {
    onDismiss();
  });

  const [{ user }] = useStateValue();
  const { leaveTeam: handleLeaveTeam } = useTeamsCache();
  const { leaveProject: handleLeaveProject } = useProjectsCache();

  return (
    <div className={collaborationStyle.container} ref={modalRef}>
      <CollaborationHeaderNew title={`Leave ${type}`} subtitle={subtitle} onDismiss={onDismiss} />
      <div className={style.leaveButtons}>
        <button
          className={manageStyle.manageButton}
          onClick={() => {
            onDismiss();
          }}
        >
          {" "}
          Cancel
        </button>
        <StyledButton
          title={`Leave`}
          style={ButtonStyle.filled}
          size={ButtonSize.regular}
          customStyle={{
            width: "78px",
            height: "32px",
            letterSpacing: "0.124px",
            borderRadius: "6px",
            color: "#FFF",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
          }}
          onClick={() => {
            if (type === CollaborationType.Team && team) {
              handleLeaveTeam({
                ...team,
                userTeamPermissions: team.userTeamPermissions.map((permission) => {
                  return permission.id === user?.id ? { ...permission, state: State.deleted } : permission;
                }),
              });
            } else {
              project &&
                handleLeaveProject({
                  ...project,
                  userProjectPermissions: project.userProjectPermissions.map((permission) => {
                    return permission.id === user?.id ? { ...permission, state: State.deleted } : permission;
                  }),
                });
            }
            onDismiss();
          }}
        />
      </div>
    </div>
  );
}
