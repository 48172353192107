import React from "react";
import style from "./no-canvases-empty-state-new.module.css";
import StyledButton, { ButtonStyle } from "frontend/ui-components/styled-button";
import { CanvasTemplate, BlankCanvasTemplate } from "shared/datamodel/schemas";
import { getPathPrefix } from "../utils/getPathPrefix";

export default function NoCanvasesEmptyStateNew({
  subtitle,
  createNewBoard,
  onDismiss,
  selectedTeamId,
  selectedProjectId,
}: {
  subtitle: string;
  createNewBoard?: ({
    template,
    inputProjectId,
    inputTeamId,
    source,
  }: {
    template: CanvasTemplate;
    inputProjectId: number | null;
    inputTeamId: number | null;
    source: string;
  }) => void;
  onDismiss?: () => void;
  selectedTeamId: number | null;
  selectedProjectId: number | null;
}) {
  return (
    <div className={style.noCanvases}>
      <img src={getPathPrefix("/images/no-canvas-empty-state.png")} alt="" />
      <span>{subtitle}</span>
      <StyledButton
        style={ButtonStyle.filled}
        title={"Create a new canvas"}
        onClick={() => {
          createNewBoard &&
            createNewBoard({
              template: BlankCanvasTemplate,
              inputProjectId: selectedProjectId,
              inputTeamId: selectedTeamId,
              source: "empty-team-or-project",
            });
          onDismiss && onDismiss();
        }}
        customStyle={{
          letterSpacing: "0.124px",
          borderRadius: "6px",
          color: "#FFF",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 400,
          lineHeight: "normal",
        }}
      />
    </div>
  );
}
