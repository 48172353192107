import useStateValue from "frontend/state/value";
import useOutsideRef from "frontend/utils/click-outside-handler";
import React, { useEffect, useRef, useState } from "react";
import style from "./invite-modal-new.module.css";
import tracking from "frontend/tracking";
import consts from "shared/consts";
import { Team } from "shared/datamodel/schemas/team";
import BulkPermissionRound from "./collaboration/team/bulk-permission-round";
import { Permission } from "shared/datamodel/schemas";
import StyledButton from "frontend/ui-components/styled-button";
import { InviteMode } from "state-atoms";
import { useInvite } from "frontend/hooks/use-invite";
import { renderResponseStrip } from "frontend/ui-components/invite-emails";
import classNames from "classnames";
import { Project } from "shared/datamodel/schemas/project";
import NewPaidSeatsModal from "./new-paid-seats-modal";
import { getPathPrefix } from "../utils/getPathPrefix";
import { Account } from "shared/datamodel/schemas/account";
import { InstanceType } from "shared/datamodel/schemas/ability";
import useInviteNew from "frontend/hooks/use-invite-new";
import { InviteResponse } from "utils/invite-utils";
import FormBubblesField from "frontend/ui-components/form-fields/form-bubbles-field";

export default function InviteModalNew({
  name,
  instance,
  type,
  onDismiss,
  onEditSeats,
  inviteMode,
  source,
}: {
  name?: string;
  instance: Account | Team | Project;
  type: InstanceType;
  onDismiss: () => void;
  onEditSeats: () => void;
  inviteMode: InviteMode;
  source: string;
}) {
  const [{ user }] = useStateValue();

  const modalRef = useRef(null);
  const submitButtonRef = useRef<HTMLDivElement>(null);

  const isFreePlan = !user || !user.planInfo || user.planInfo.is_free;
  const isTouchAccount = user?.planInfo?.source === "touch";
  const canManageSeats = !isFreePlan && !isTouchAccount && user.isAdmin && user.isSubscribedToCanvas;
  const [usersPermission, setUsersPermission] = useState<Permission>(Permission.editor);
  const [shouldRenderNewPaidSeatsModal, setShouldRenderNewPaidSeatsModal] = useState(false);
  const { inviteUsersResponse } = useInvite();
  name ??= instance.name;

  const { users, failed, addedSeats, changeEmails, emails, highlightInvalidEmails, loading, handleInviteClick } =
    useInviteNew({
      bulkPermission: usersPermission,
      instance,
      instanceType: type,
      source,
    });

  const invalidCount = emails.filter((v) => !v.isValid).length;

  useOutsideRef(modalRef, onDismiss, { preventScroll: false });

  useEffect(() => {
    tracking.trackAnalyticsEvent("invite-modal-opened", {
      category: consts.TRACKING_CATEGORY.INVITE_ACTION,
      source: source,
    });
  }, []);

  useEffect(() => {
    if (addedSeats > 0 && !user?.planInfo?.is_free) {
      !isTouchAccount && setShouldRenderNewPaidSeatsModal(true);
    }
  }, [isTouchAccount, addedSeats, user?.planInfo?.is_free]);

  function renderCloseIcon() {
    return (
      <svg
        className={style.closeIcon}
        onClick={onDismiss}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="close-template-modal"
      >
        <path
          d="M6.36407 6.36396L19.092 19.0919M19.092 6.36396L6.36407 19.0919"
          stroke="currentColor"
          strokeWidth="1.74"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function getName() {
    if (inviteMode === InviteMode.Create) {
      if (type === InstanceType.Project) {
        return `"${name}" project`;
      } else {
        return `"${name}" team`;
      }
    }
    if (type === InstanceType.Account) {
      return "your account";
    }
    const teamName = type === InstanceType.Team ? (instance as Team)?.name : name;
    if (type === InstanceType.Project) {
      return `"${instance.name}" project`;
    } else {
      return `"${teamName}" team`;
    }
  }

  function renderEmailsInput() {
    return (
      <div style={{ padding: "25px 25px 0 25px" }}>
        <div className={style.fieldContainer}>
          {renderResponseStrip({ usersCount: users.length, failed, addedSeats } as InviteResponse)}
          <FormBubblesField
            id={"emails"}
            autoFocus={true}
            onChange={(e) => changeEmails(e, source)}
            value={emails}
            placeholder="Enter email addresses"
            highlightInvalid={highlightInvalidEmails}
            submitButtonRef={submitButtonRef}
            rounded={true}
          />
          {invalidCount > 0 && (
            <div className={style.dynamicErrorMessage}>
              <img src={getPathPrefix("/images/error-info-icon.svg")} alt="" />
              <span>
                {`you have entered ${invalidCount} invalid email addresses. Please check them from typos and enter new ones.`}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }

  function renderFooter() {
    return (
      <div>
        <div className={classNames(style.footer, { [style.spaceBetween]: inviteMode === InviteMode.Create })}>
          {inviteMode === InviteMode.Create && name && (
            <span
              data-testid="Skip for now"
              onClick={onDismiss}
              className={classNames(style.link, { [style.disabled]: loading })}
            >
              Skip for now
            </span>
          )}
          <StyledButton
            ref={submitButtonRef}
            title={"Invite"}
            data-testid="new-invite-modal-button"
            loading={loading}
            enabled={emails.length > 0 && emails.every((email) => email.isValid)}
            loadingTitle="Inviting..."
            onClick={() => {
              handleInviteClick();
            }}
            customStyle={{
              height: "36px",
              minWidth: "115px",
              width: "fit-content",
              zIndex: 1,
              borderRadius: "6px",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              letterSpacing: "0.124px",
            }}
          />
        </div>
      </div>
    );
  }

  function renderInviteScreen() {
    return (
      <div className={style.container} ref={modalRef}>
        <div style={{ position: "relative" }}>{renderCloseIcon()}</div>
        <div className={style.header}>
          <span className={style.title}>{`Invite new people to ${getName()}`}</span>
          {canManageSeats && (
            <span className={style.manageSeats} onClick={onEditSeats} data-testid={"Manage seats"}>
              Manage seats
            </span>
          )}
        </div>
        {renderEmailsInput()}
        {type !== InstanceType.Account && (
          <BulkPermissionRound usersPermission={usersPermission} setUsersPermission={setUsersPermission} />
        )}
        {renderFooter()}
      </div>
    );
  }

  function renderNewPaidSeatsModal() {
    return <NewPaidSeatsModal onDismiss={() => onDismiss()} inviteUsersResponse={inviteUsersResponse} />;
  }

  function renderModal() {
    if (shouldRenderNewPaidSeatsModal) {
      return renderNewPaidSeatsModal();
    } else {
      return renderInviteScreen();
    }
  }

  return renderModal();
}
