import React, { useRef } from "react";
import collaborationStyle from "./collaboration-modal-new.module.css";
import style from "./new-collaboration-modal.module.css";
import manageStyle from "./manage-collaboration-modal-new.module.css";
import { Project } from "shared/datamodel/schemas/project";
import CollaborationHeaderNew from "./collaboration-header-new";
import { Team } from "shared/datamodel/schemas/team";
import useOutsideRef from "frontend/utils/click-outside-handler";
import { CollaborationType } from "state-atoms";
import { useTeamsCache } from "frontend/hooks/caching/use-teams-cache";
import { useProjectsCache } from "frontend/hooks/caching/use-projects-cache";
import { getPathPrefix } from "../../utils/getPathPrefix";

export default function DeleteCollaborationModalNew({
  type,
  subtitle,
  team,
  project,
  onDismiss,
  isSelected,
  onDeleteSelectedCollaboration,
}: {
  type: CollaborationType;
  subtitle: string;
  team?: Team;
  project?: Project;
  onDismiss: () => void;
  isSelected: boolean;
  onDeleteSelectedCollaboration: () => void;
}) {
  const { removeProject: handleDeleteProject } = useProjectsCache();
  const { removeTeam: handleDeleteTeam } = useTeamsCache();

  const modalRef = useRef(null);

  useOutsideRef(modalRef, () => {
    onDismiss();
  });

  return (
    <div className={collaborationStyle.container} ref={modalRef}>
      <CollaborationHeaderNew title={`Delete the ${type}?`} subtitle={subtitle} onDismiss={onDismiss} />
      <div className={style.leaveButtons}>
        <button
          className={manageStyle.manageButton}
          onClick={() => {
            onDismiss();
          }}
        >
          Cancel
        </button>
        <button
          className={manageStyle.deleteButton}
          onClick={() => {
            if (type === CollaborationType.Team && team) {
              handleDeleteTeam(team);
              isSelected && onDeleteSelectedCollaboration();
            } else {
              project && handleDeleteProject(project);
              project && onDeleteSelectedCollaboration();
            }
            onDismiss();
          }}
        >
          <img src={getPathPrefix("/images/delete.svg")} alt="" />
          <span>{`Delete ${type}`}</span>
        </button>
      </div>
    </div>
  );
}
