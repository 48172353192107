import React, { useEffect, useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { Board, MinimalBoard } from "shared/datamodel/schemas/board";
import { BatchedItemsGrid } from "./batched-items-grid";
import NoCanvasesEmptyStateNew from "./no-canvases-empty-state-new";
import { CanvasTemplate } from "shared/datamodel/schemas/canvas-template";
import style from "./boards-by-section.module.css";
import { navigateToBoard } from "./boards-grid-utils";
import classNames from "classnames";
import { useBoardsCache } from "frontend/hooks/caching/use-boards-cache";
import { BoardCardNew } from "./board-card-new";
import { InstanceType } from "shared/datamodel/schemas/ability";
import { useTeamsWithProjects } from "frontend/hooks/caching/use-teams-with-projects";
import { BoardSection, BoardSectionType, getSectionId } from "shared/datamodel/schemas/board-section";

export default function BoardsBySection({
  section,
  onSelected,
  createNewBoard,
  onDuplicateError,
  setCanvasToMove,
  selectedTeamId,
  selectedProjectId,
}: {
  section: BoardSection;
  onSelected: (documentId: string) => void;
  createNewBoard: ({
    template,
    inputProjectId,
    inputTeamId,
    source,
  }: {
    template: CanvasTemplate;
    inputProjectId: number | null;
    inputTeamId: number | null;
    source: string;
  }) => void;
  onDuplicateError: (number: number) => void;
  setCanvasToMove: (board: Board | null) => void;
  selectedTeamId: number | null;
  selectedProjectId: number | null;
}) {
  const {
    isLoading: isLoadingBoards,
    getSection,
    fetchRecentBoards,
    fetchMyBoards,
    fetchSharedWithMeBoards,
    fetchBoardsByInstance,
  } = useBoardsCache();
  const { teamsWithProjects } = useTeamsWithProjects();

  const sectionId = getSectionId(section.type, section.id);
  const currentBoards = useMemo(() => getSection(sectionId), [sectionId, getSection]);

  useEffect(() => {
    if (section.type === BoardSectionType.RECENT) {
      fetchRecentBoards();
    }
    if (section.type === BoardSectionType.MY_BOARDS) {
      fetchMyBoards();
    }
    if (section.type === BoardSectionType.SHARED_WITH_ME) {
      fetchSharedWithMeBoards();
    }
    if (section.type === BoardSectionType.TEAM) {
      fetchBoardsByInstance({ instanceId: section.id, instanceType: InstanceType.Team });
    }
    if (section.type === BoardSectionType.PROJECT) {
      fetchBoardsByInstance({ instanceId: section.id, instanceType: InstanceType.Project });
    }
  }, [section.id, section.type, fetchRecentBoards, fetchMyBoards, fetchBoardsByInstance]);

  const parentNameMap = useMemo(() => {
    const map = new Map<string, string>();
    for (const team of teamsWithProjects) {
      for (const project of team.projects || []) {
        map.set(`project-${project.id}`, project.name);
      }
    }
    for (const team of teamsWithProjects) {
      map.set(`team-${team.id}`, team.name);
    }

    return map;
  }, [teamsWithProjects]);

  const getParentName = (board: MinimalBoard) => {
    if (board.projectId) {
      return parentNameMap.get(`project-${board.projectId}`) || "";
    }
    if (board.teamId) {
      return parentNameMap.get(`team-${board.teamId}`) || "";
    }
    return "";
  };

  const renderCard = (board: MinimalBoard, index: number) => {
    return (
      <BoardCardNew
        onClick={() => {
          onSelected(board.documentId);
          navigateToBoard(board);
        }}
        board={board}
        parentName={getParentName(board) ?? ""}
        key={board.id}
        showDynamicPreview={false}
        boardIndex={index}
        onDuplicateError={onDuplicateError}
        setCanvasToMove={setCanvasToMove}
      />
    );
  };

  const renderBoardsEmptyState = () => {
    const emptyStateMessages: Record<Exclude<BoardSectionType, BoardSectionType.TEMPLATES>, string> = {
      [BoardSectionType.PROJECT]: "No canvases in this project yet!",
      [BoardSectionType.TEAM]: "No canvases in this team yet!",
      [BoardSectionType.MY_BOARDS]: "No canvases yet!",
      [BoardSectionType.RECENT]: "No recent canvases yet!",
      [BoardSectionType.SHARED_WITH_ME]: "No canvases shared with you yet!",
    };

    if (section.type === BoardSectionType.TEMPLATES) return null;

    return (
      <NoCanvasesEmptyStateNew
        subtitle={emptyStateMessages[section.type]}
        createNewBoard={createNewBoard}
        selectedTeamId={selectedTeamId}
        selectedProjectId={selectedProjectId}
      />
    );
  };

  const renderSectionName = () => {
    let name = "";
    switch (section.type) {
      case BoardSectionType.PROJECT: {
        const team = teamsWithProjects.find((team) => team.projects?.find((project) => project.id === section.id));
        if (team) {
          name = team?.projects?.find((project) => project.id === section.id)?.name ?? "";
        }
        break;
      }
      case BoardSectionType.TEAM: {
        name = teamsWithProjects.find((team) => team.id === section.id)?.name ?? "";
        break;
      }
      default: {
        // Other section names are constant
        break;
      }
    }
    const sectionNames: Record<Exclude<BoardSectionType, BoardSectionType.TEMPLATES>, string> = {
      [BoardSectionType.PROJECT]: `Canvases in "${name}" project`,
      [BoardSectionType.TEAM]: `Canvases in "${name}" team`,
      [BoardSectionType.MY_BOARDS]: "My Canvases",
      [BoardSectionType.RECENT]: "Recent Canvases",
      [BoardSectionType.SHARED_WITH_ME]: "Shared with me",
    };
    if (section.type === BoardSectionType.TEMPLATES) return "Templates";
    return sectionNames[section.type];
  };

  const renderContent = () => {
    if (isLoadingBoards) {
      return (
        <>
          <Skeleton count={1} className={classNames(style.titleLine, style.titleLineSkeleton)} />
          <Skeleton count={20} className={style.skeletonCard} containerClassName={style.skeletonCardContainer} />
        </>
      );
    }

    return (
      <div className={style.section}>
        <span className={style.titleLine}>{renderSectionName()}</span>
        {currentBoards.length === 0 && !isLoadingBoards ? (
          renderBoardsEmptyState()
        ) : (
          <BatchedItemsGrid
            items={currentBoards}
            gridContainerClass={style.boardsGrid}
            cellRender={renderCard}
            batchSize={50}
          />
        )}
      </div>
    );
  };

  return <div className={style.boardsContainer}>{renderContent()}</div>;
}
