import React from "react";
import collaborationStyle from "./collaboration-modal-new.module.css";
import { BackArrow } from "frontend/ui-components/svg-shapes";
import classNames from "classnames";

export default function CollaborationHeaderNew({
  title,
  subtitle,
  onDismiss,
  onBack,
}: {
  title: string;
  subtitle?: string;
  onDismiss: () => void;
  onBack?: () => void;
}) {
  function renderCloseIcon() {
    return (
      <svg
        className={collaborationStyle.closeIcon}
        onClick={onDismiss}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="close-template-modal"
      >
        <path
          d="M6.36407 6.36396L19.092 19.0919M19.092 6.36396L6.36407 19.0919"
          stroke="currentColor"
          strokeWidth="1.74"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function renderBackArrow() {
    return (
      <BackArrow
        onClick={() => onBack && onBack()}
        color={"#848199"}
        style={{
          cursor: "pointer",
          position: "absolute",
          top: "14px",
          left: "1.3em",
        }}
      />
    );
  }

  return (
    <>
      {renderCloseIcon()}
      {onBack && renderBackArrow()}
      <div className={classNames(collaborationStyle.header, { [collaborationStyle.extraPaddingTop]: onBack })}>
        <p className={collaborationStyle.title}>{title}</p>
        {subtitle && <p className={collaborationStyle.subtitle}> {subtitle}</p>}
      </div>
    </>
  );
}
