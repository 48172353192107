import React from "react";

export const DuplicateIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
      <path
        d="M3.88965 5.81501C3.88965 5.30416 4.09258 4.81423 4.45381 4.453C4.81504 4.09177 5.30496 3.88884 5.81582 3.88884H12.0746C12.3275 3.88884 12.578 3.93866 12.8117 4.03546C13.0454 4.13226 13.2577 4.27414 13.4366 4.453C13.6155 4.63186 13.7573 4.8442 13.8541 5.07789C13.9509 5.31159 14.0008 5.56206 14.0008 5.81501V12.0738C14.0008 12.3267 13.9509 12.5772 13.8541 12.8109C13.7573 13.0446 13.6155 13.2569 13.4366 13.4358C13.2577 13.6147 13.0454 13.7565 12.8117 13.8533C12.578 13.9501 12.3275 14 12.0746 14H5.81582C5.56287 14 5.3124 13.9501 5.0787 13.8533C4.84501 13.7565 4.63267 13.6147 4.45381 13.4358C4.27495 13.2569 4.13307 13.0446 4.03627 12.8109C3.93947 12.5772 3.88965 12.3267 3.88965 12.0738V5.81501Z"
        stroke="#113357"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.73089 10.9212C1.50941 10.7949 1.3252 10.6124 1.19686 10.3921C1.06852 10.1719 1.00061 9.92161 1 9.66667V2.44444C1 1.65 1.65 1 2.44444 1H9.66667C10.2083 1 10.503 1.27806 10.75 1.72222"
        stroke="#113357"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
