import { useAtomValue } from "jotai";
import { userAtom } from "state-atoms";
import { CanvasTemplateMinimal } from "shared/datamodel/schemas";
import style from "./templates-section-new.module.css";
import ExploreTemplatesListNew from "./explore-templates-list-new";
import useHomeRecommendedTemplates from "frontend/hooks/use-home-recommended-templates";

export default function TemplatesSectionNew({
  onCreateBoard,
  onPreviewClicked,
  setShowTemplatesModal,
}: {
  onCreateBoard: (template: CanvasTemplateMinimal) => void;
  onPreviewClicked: (template: CanvasTemplateMinimal) => void;
  setShowTemplatesModal: (show: boolean) => void;
}) {
  const user = useAtomValue(userAtom);

  const templates = useHomeRecommendedTemplates();

  if (!user?.planInfo) {
    return null;
  }

  return (
    <div className={style.section}>
      <div className={style.titleContainer}>
        <p className={style.titleLine}>Create a new canvas</p>
        <p className={style.subtitleLine}>Explore our recommended templates</p>
      </div>
      <div className={style.grid}>
        <ExploreTemplatesListNew
          templates={templates}
          onCreateBoard={onCreateBoard}
          onPreviewClicked={onPreviewClicked}
          setShowTemplatesModal={setShowTemplatesModal}
        />
      </div>
    </div>
  );
}
