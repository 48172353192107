import React from "react";

export const RenameIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <path
        d="M4.13604 12L1.33 12C1.14775 12 1 11.8523 1 11.67L1 8.86396C1 8.77644 1.03477 8.6925 1.09665 8.63062L8.09091 1.63636C8.99465 0.732625 10.4599 0.732625 11.3636 1.63636C12.2674 2.5401 12.2674 4.00535 11.3636 4.90909L4.36938 11.9033C4.3075 11.9652 4.22356 12 4.13604 12Z"
        stroke="#113357"
        strokeWidth="1.2"
      />
      <path d="M7 2L10.3333 5.33333" stroke="#113357" strokeWidth="1.2" />
    </svg>
  );
};
